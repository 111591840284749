import { compose, createStore, applyMiddleware, combineReducers, } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { themeReducer, adminReducer, clientReducer, custodyReducer, subOrdersReducer, orderBookReducer, apiErrorsReducer, workSpaceReducer, placeOrderReducer, clientSearchReducer, customerSearchReducer, widgetSettingsReducer, widgetsReducer, masterTenantReducer, currenciesReducer, runtimeSettingsReducer, } from '../../redux/reducers/index';
import rootSaga from '../../sagas/sagas';
const sagaMiddleware = createSagaMiddleware();
export const rootReducer = combineReducers({
    currencies: currenciesReducer,
    theme: themeReducer,
    subOrders: subOrdersReducer,
    orderBook: orderBookReducer,
    widgetSettings: widgetSettingsReducer,
    placeOrder: placeOrderReducer,
    client: clientReducer,
    customersSearch: customerSearchReducer,
    apiErrors: apiErrorsReducer,
    admin: adminReducer,
    clientSearch: clientSearchReducer,
    workspace: workSpaceReducer,
    custody: custodyReducer,
    widgets: widgetsReducer,
    masterTenant: masterTenantReducer,
    runtimeSettings: runtimeSettingsReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, {}, composeEnhancer(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);
export default store;

export var TransferStatus;
(function (TransferStatus) {
    TransferStatus["UserInputReference"] = "user-input-required-reference";
    TransferStatus["UserInputDeposit"] = "user-input-required-deposit";
    TransferStatus["Completed"] = "completed";
    TransferStatus["Failed"] = "failed";
    TransferStatus["Reverted"] = "reverted";
})(TransferStatus || (TransferStatus = {}));
export var TransferIcon;
(function (TransferIcon) {
    TransferIcon["user-input-required"] = "warning";
    // 'completed' = '',
    // 'failed' = '',
    // 'reverted' = '',
})(TransferIcon || (TransferIcon = {}));
export var TransferStatusLabel;
(function (TransferStatusLabel) {
    TransferStatusLabel["user-input-required"] = "Action Required";
    TransferStatusLabel["completed"] = "Completed";
    // 'failed' = '',
    // 'reverted' = '',
})(TransferStatusLabel || (TransferStatusLabel = {}));
export var TransferStatusColor;
(function (TransferStatusColor) {
    TransferStatusColor["user-input-required"] = "orange";
    TransferStatusColor["completed"] = "primary";
    // 'failed' = '',
    // 'reverted' = '',
})(TransferStatusColor || (TransferStatusColor = {}));
export var TransactionActivity;
(function (TransactionActivity) {
    TransactionActivity["BankTransferValidateCustomerActivity"] = "BankTransferValidateCustomerActivity";
    TransactionActivity["BankTransferValidateDepositActivity"] = "BankTransferValidateDepositActivity";
    TransactionActivity["BankTransferCreateInternalTransferActivity"] = "BankTransferCreateInternalTransferActivity";
    TransactionActivity["BankTransferCreateReversalActivity"] = "BankTransferCreateReversalActivity";
})(TransactionActivity || (TransactionActivity = {}));

import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DLTAutocomplete from '../../../../../shared/components/form/DLTAutocomplete';
import axios from 'axios';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import showNotification from '../../../../../shared/helpers/notifications';
import Messages from '../../../../../shared/helpers/errorMessages';
import { connect } from 'react-redux';
import { getCustomersSelectedInputInterface } from '../../../../../redux/selectors/customerSearchSelectors';
import { CUSTOMER_SEARCH_SET_CLIENT, } from '../../../../../redux/actionTypes/customerSearchActionsTypes';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import LoadingButton from '@mui/lab/LoadingButton';
import { getCustodyCurrenciesOptions } from '../../../../../redux/selectors/currenciesSelectors';
import { DateTimePicker } from '@mui/x-date-pickers';
const setCustomersClient = (client) => ({
    type: CUSTOMER_SEARCH_SET_CLIENT,
    selectedClient: client,
});
const InitiateSettlements = (props) => {
    var _a, _b;
    const { customers, clients, currencies, dispatchClientSelect, depositHolder, withDates } = props;
    const formSchema = yup.object({
        currency: withDates ? yup.string().nullable() : yup.string().required().nullable(),
        client: withDates ? yup.string().nullable() : yup.string().required().nullable(),
        customer: withDates ? yup.string().nullable() : yup.string().required().nullable(),
        from: withDates ? yup.date().required() : yup.date().nullable(),
        to: withDates ? yup.date().required() : yup.date().nullable(),
    });
    const { handleSubmit, control, formState, getValues, trigger } = useForm({
        defaultValues: formSchema.cast({
            currency: null,
            client: null,
            customer: null,
            from: new Date(new Date().setSeconds(0, 0)),
            to: new Date(new Date().setSeconds(0, 0)),
        }),
        resolver: yupResolver(formSchema),
    });
    const [loading, setLoading] = useState(false);
    const selectedClient = useWatch({ control, name: 'client' });
    const handleInitiateSettlements = (data) => {
        let dateFrom = null;
        let dateTo = null;
        if (withDates && data.from && data.to) {
            dateFrom = data.from.toISOString();
            dateTo = data.to.toISOString();
        }
        setLoading(true);
        const params = {
            deposit_holder_code: withDates ? null : (data.client || data.customer ? null : depositHolder),
            client_code: withDates ? getEnvSettings().etoroClientId : data.client,
            customer_code: data.customer,
            currency_code: data.currency,
            from: dateFrom,
            to: dateTo,
        };
        axios
            .post('/settlements/accounts/create_manually', params, { baseURL: getEnvSettings().adminApiUrl })
            .then(() => {
            props.toggleDialog(true);
            showNotification({
                message: `Settlements were initiated`,
                color: 'success',
            });
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_INITIATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
            });
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        if (selectedClient !== undefined)
            dispatchClientSelect(selectedClient);
    }, [selectedClient]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DialogTitle, null, "Initiate Settlements"),
        React.createElement(Box, { component: 'form', onSubmit: handleSubmit((data) => handleInitiateSettlements(data)) },
            React.createElement(DialogContent, null, !withDates ? (React.createElement(Box, { sx: { display: 'flex', flexWrap: 'wrap' }, gap: 2 },
                React.createElement(DLTAutocomplete, { fullWidth: true, name: 'currency', label: 'Currency', options: currencies, control: control }),
                React.createElement(DLTAutocomplete, { fullWidth: true, label: 'Client', name: 'client', options: clients, control: control }),
                React.createElement(DLTAutocomplete, { disabled: !selectedClient, fullWidth: true, label: 'Customer', name: 'customer', options: customers, control: control }))) : (React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', width: '100%' } },
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(Controller, { control: control, name: 'from', rules: { required: true }, render: ({ field }) => {
                            return (React.createElement(DateTimePicker, { label: 'Date from', format: 'dd/MM/yyyy HH:mm', disableFuture: true, ampm: false, slotProps: { textField: { size: 'small' } }, value: field.value, inputRef: field.ref, onChange: (date) => {
                                    field.onChange(date);
                                    trigger('from');
                                } }));
                        } }),
                    React.createElement(Typography, { variant: 'caption' }, (_a = getValues('from')) === null || _a === void 0 ? void 0 : _a.toUTCString())),
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(Controller, { control: control, name: 'to', rules: { required: true }, render: ({ field }) => {
                            return (React.createElement(DateTimePicker, { label: 'Date to', disableFuture: true, format: 'dd/MM/yyyy HH:mm', ampm: false, value: field.value, inputRef: field.ref, slotProps: { textField: { size: 'small' } }, onChange: (date) => {
                                    field.onChange(date);
                                    trigger('to');
                                } }));
                        } }),
                    React.createElement(Typography, { variant: 'caption' }, (_b = getValues('to')) === null || _b === void 0 ? void 0 : _b.toUTCString()))))),
            React.createElement(DialogActions, null,
                React.createElement(LoadingButton, { loading: loading, type: 'submit', variant: 'contained', disabled: !formState.isValid }, "Initiate"),
                React.createElement(Button, { onClick: () => props.toggleDialog(false) }, "Cancel")))));
};
const mapStateToProps = (state) => {
    var _a;
    const mappedCustomers = getCustomersSelectedInputInterface(state).map((c) => ({
        value: c.value,
        label: c.label,
    }));
    mappedCustomers.unshift({ value: null, label: 'All' });
    const mappedClients = state.clientSearch.allClients.map((c) => ({
        value: c.code,
        label: c.company_name,
    }));
    mappedClients.unshift({ value: null, label: 'All' });
    return {
        customers: mappedCustomers,
        clients: mappedClients,
        currencies: getCustodyCurrenciesOptions(state),
        depositHolder: ((_a = state.client.clientInfo) === null || _a === void 0 ? void 0 : _a.deposit_holder_code) || null,
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatchClientSelect: (client) => dispatch(setCustomersClient(client)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InitiateSettlements);

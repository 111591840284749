import { Box, Button, Checkbox, Collapse, IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import DLTEditableCell from './DLTEditableCell';
import { DateCell, TimeCell } from '../styled/tableCells';
import { CELL_TYPE } from './DLTTableModel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { format, parseISO } from 'date-fns';
import { NumericFormat } from 'react-number-format';
import { Amount } from '../styled/amount';
import { amountWithPrecision } from '../../helpers/amountWithPrecision';
const DLTTableRowComponent = (props) => {
    const { row, bulkSelect, columns, onRowClick, onCellEdit } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [rowCollapsed, setRowCollapsed] = useState(true);
    const actionsOpen = Boolean(anchorEl);
    const handleActionsOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };
    const handleRowClick = (id) => {
        if (onRowClick) {
            onRowClick(id);
        }
    };
    const handleCellEdit = (id, entity, value) => {
        if (onCellEdit) {
            onCellEdit(id, entity, value);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { hover: true, onClick: () => handleRowClick(row.id), role: 'checkbox', tabIndex: -1, sx: { cursor: 'pointer' } },
            !bulkSelect ? null : (React.createElement(TableCell, { padding: 'checkbox' },
                React.createElement(Checkbox, { color: 'primary', inputProps: {
                        'aria-labelledby': row.id,
                    } }))),
            React.createElement(React.Fragment, null, columns.map((column, index) => {
                var _a;
                return column.id !== 'actions' ? (React.createElement(TableCell, { key: column.id, align: column.type === CELL_TYPE.NUMERIC ? 'right' : column.align || 'left', sx: { width: `${column.width}`, whiteSpace: column.wrap ? 'normal' : 'nowrap' } },
                    React.createElement(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            justifyContent: column.type === CELL_TYPE.NUMERIC ? 'end' : column.align === 'right' ? 'end' : 'start',
                        } },
                        index === 0 ? (row.collapsible ? (React.createElement(IconButton, { disabled: !row.children, onClick: () => setRowCollapsed(!rowCollapsed) }, !rowCollapsed ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null))) : null) : null,
                        column.editable ? (React.createElement(DLTEditableCell, { type: column.type, value: row[column.id] || 'N/A', name: column.id, onSave: (val) => handleCellEdit(row.id, column.id, val) })) : column.type === CELL_TYPE.DATE_TIME ? (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } }, row[column.id] ? (React.createElement(React.Fragment, null,
                            React.createElement(DateCell, null, format(parseISO(row[column.id]), 'dd-MM-yyyy')),
                            React.createElement(TimeCell, null, format(parseISO(row[column.id]), 'HH:mm:ss')))) : ('N/A'))) : column.type === CELL_TYPE.NUMERIC ? (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } }, row[column.id] ? (React.createElement(Amount, null,
                            React.createElement(NumericFormat, { displayType: 'text', value: amountWithPrecision(row[column.id], row.precision || 2), thousandSeparator: "'" }))) : ('N/A'))) : column.type === CELL_TYPE.ID ? (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } }, row[column.id] ? React.createElement(Amount, null, row[column.id]) : 'N/A')) : (row[column.id] || 'N/A')))) : (React.createElement(TableCell, { key: column.id, align: 'right' },
                    React.createElement(Button, { disabled: !row.actions || !row.actions.length, id: `action-${row.id}`, "aria-controls": actionsOpen ? 'basic-menu' : undefined, "aria-haspopup": 'true', "aria-expanded": actionsOpen ? 'true' : undefined, onClick: handleActionsOpen },
                        React.createElement(MoreHorizIcon, null)),
                    React.createElement(Menu, { anchorEl: anchorEl, open: (anchorEl === null || anchorEl === void 0 ? void 0 : anchorEl.id) === `action-${row.id}`, onClose: handleActionsClose }, (_a = row.actions) === null || _a === void 0 ? void 0 : _a.map((action, index) => (React.createElement(MenuItem, { key: index, onClick: () => {
                            action.action(row.id), setAnchorEl(null);
                        } }, action.label))))));
            }))),
        row.collapsible ? (React.createElement(TableRow, { key: row.id, sx: { border: 0 } },
            React.createElement(TableCell, { style: { padding: 0 }, colSpan: columns.length },
                React.createElement(Collapse, { in: !rowCollapsed }, row.children)))) : null));
};
export default DLTTableRowComponent;

import { Box, Typography } from '@mui/material';
import { Amount } from '@shared/components/styled/amount';
import React from 'react';
const LabeledTypography = (props) => {
    return (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: props.orientation ? props.orientation : 'column',
            gap: props.orientation === 'row' ? 1 : 0,
            color: props.color ? props.color : 'inherit',
        } },
        React.createElement(Typography, { variant: 'caption' }, props.label),
        React.createElement(Amount, null, props.value)));
};
export default LabeledTypography;

import CompletedIcon from '@mui/icons-material/CheckCircle';
import FailedIcon from '@mui/icons-material/Error';
import ConstructionSharpIcon from '@mui/icons-material/ConstructionSharp';
import SyncAltTwoToneIcon from '@mui/icons-material/SyncAltTwoTone';
const statusDetails = {
    [TransferStatus.UserInputDeposit]: {
        icon: React.createElement(ConstructionSharpIcon, { color: "warning" }),
        text: 'Action required',
        color: 'warning.main',
    },
    [TransferStatus.UserInputReference]: {
        icon: React.createElement(ConstructionSharpIcon, { color: "warning" }),
        text: 'Action required',
        color: 'warning.main',
    },
    [TransferStatus.Completed]: {
        icon: React.createElement(CompletedIcon, { color: "success" }),
        text: 'Completed',
        color: 'success.main',
    },
    [TransferStatus.Failed]: {
        icon: React.createElement(FailedIcon, { color: "error" }),
        text: 'Failed',
        color: 'error.main',
    },
    [TransferStatus.Reverted]: {
        icon: React.createElement(SyncAltTwoToneIcon, { color: "info" }),
        text: 'Reversed',
        color: 'info.main',
    },
};
import React from 'react';
import { Box, Typography } from '@mui/material';
import { TransferStatus } from './transfersModel';
const TransactionStatusDisplay = ({ status }) => {
    const statusDetail = statusDetails[status];
    if (!statusDetail) {
        return null;
    }
    return (React.createElement(Box, { sx: {
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            borderRadius: 1,
            p: 1,
        } },
        statusDetail.icon,
        React.createElement(Typography, { variant: "body1", color: statusDetail.color }, statusDetail.text)));
};
export default TransactionStatusDisplay;

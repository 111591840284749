import React from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import TransactionStatusDisplay from '../TransactionStatus';
import DetailsSectionBlock from '../Components/SectionDetailsBlock';
import LabeledTypography from '../Components/LabeledTypography';
import { format } from 'date-fns';
const TransactionUserInputDepositDetails = ({ transaction, onClose, onApprove, onReject }) => {
    return (React.createElement(Box, null,
        React.createElement(DialogTitle, null,
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                "Deposit Details",
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 } },
                    React.createElement(TransactionStatusDisplay, { status: transaction.state })))),
        React.createElement(DialogContent, null,
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 2 } },
                React.createElement(DetailsSectionBlock, { label: 'Deposit Overview' },
                    React.createElement(LabeledTypography, { value: transaction.depositor_iban, label: 'Deposit IBAN', color: 'warning.main' }),
                    React.createElement(LabeledTypography, { value: transaction.depositor_name, label: 'Deposit Name', color: 'warning.main' }),
                    React.createElement(LabeledTypography, { value: `${transaction.transaction_amount} ${transaction.transaction_currency}`, label: 'Amount' }),
                    React.createElement(LabeledTypography, { value: transaction.transaction_ref, label: 'Reference' }),
                    React.createElement(LabeledTypography, { value: format(new Date(transaction.transaction_date), 'dd-MM-yyyy HH:mm'), label: 'Date' })),
                React.createElement(DetailsSectionBlock, { label: 'System Info' },
                    React.createElement(Box, null,
                        React.createElement(LabeledTypography, { value: transaction.transaction_id, label: 'Transaction ID:', orientation: 'row' }),
                        React.createElement(LabeledTypography, { value: transaction.workflow_id, label: 'Workflow ID:', orientation: 'row' }),
                        React.createElement(LabeledTypography, { value: transaction.workflow_uuid, label: 'Workflow UUID:', orientation: 'row' }))),
                React.createElement(DetailsSectionBlock, { label: 'Error', color: 'error.main' },
                    React.createElement(Typography, { variant: 'body2' }, transaction.error_message || 'No error despcription available')))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { variant: 'contained', color: 'primary', size: 'small', onClick: onApprove }, "Approve"),
            React.createElement(Button, { variant: 'contained', color: 'error', size: 'small', onClick: onReject }, "Reject"),
            React.createElement(Button, { variant: 'outlined', color: 'inherit', size: 'small', onClick: onClose }, "Close"))));
};
export default TransactionUserInputDepositDetails;

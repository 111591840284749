import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Decimal from 'decimal.js';
import { useHistory } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import { NumericFormat } from 'react-number-format';
import DialogTitle from '@mui/material/DialogTitle';
import { useResizeDetector } from 'react-resize-detector';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Dialog, Button, Chip, Link, Box, Checkbox } from '@mui/material';
import Main from '../MegaModal/main';
import { TransfersCellItem } from '../../styling/style';
import { statusBadgeData } from '../../../../../config/statusBadgeData';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import getDecimalsByCurrencyCode from '../../../../../config/currencyDecimal';
import { getExplorerURL } from '../../../../../shared/helpers/getExplorerURL';
import statusBadgeColor from '../../../../../shared/helpers/statusBadgeColor';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
import shortid from 'shortid';
const envSettings = getEnvSettings();
const WithdrawalsTableCell = ({ value, columnKey, currency, currencyFee, walletsLoading, item, withdrawals, currencies, bulk, handleBulkChange, }) => {
    const history = useHistory();
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const [fullItem, setFullItem] = useState(null);
    const toggleDialog = (e) => {
        e && e.preventDefault();
        toggleOpenDialog((prev) => !prev);
        history.push('/custody');
    };
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    useEffect(() => {
        const withdraw = withdrawals.find((w) => w.code === item.code);
        if (withdraw) {
            setFullItem(withdraw);
        }
    }, [withdrawals, item]);
    if (columnKey === 'address') {
        return (React.createElement(TableCell, { align: 'left', ref: ref },
            React.createElement(Box, { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
                React.createElement(Box, { display: 'flex', flexDirection: 'row', alignItems: 'center' },
                    value ? React.createElement(CopyValue, { value: value }) : null,
                    React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value)))));
    }
    if (columnKey === 'source_address') {
        return (React.createElement(TableCell, { align: 'left', ref: ref },
            value ? React.createElement(CopyValue, { value: value }) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value)));
    }
    if (columnKey === 'requested_amount') {
        const bulkItem = bulk.find((b) => b.code === item.code);
        const checked = (bulkItem && bulkItem.value) || false;
        const idKey = shortid.generate();
        return (React.createElement(TableCell, { align: 'left' },
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
                React.createElement(Box, null, idKey ? (React.createElement("label", { htmlFor: idKey, className: 'checkbox-btn', style: { marginBottom: '5px' } },
                    React.createElement(Checkbox, { id: idKey, name: idKey, checked: checked, className: 'checkbox-btn__checkbox', onChange: (e) => handleBulkChange(e, item.code) }))) : null),
                React.createElement(Box, null,
                    React.createElement(NumericFormat, { displayType: 'text', value: new Decimal(value || 0).toFixed(), thousandSeparator: envSettings.thousandDivider })))));
    }
    if (columnKey === 'requested_amount_usd' || columnKey === 'usd_fee') {
        if (value === 'Not convertible' || value === '') {
            return React.createElement(TableCell, { align: 'left' }, "N/A");
        }
        const decimals = getDecimalsByCurrencyCode('USD', currencies) || 2;
        return (React.createElement(TableCell, { align: 'left' },
            value === '0' || value === '0.00' ? (value) : (React.createElement(NumericFormat, { displayType: 'text', value: new Decimal(value || 0).toFixed(decimals), thousandSeparator: envSettings.thousandDivider })),
            ' ',
            "$"));
    }
    if (columnKey === 'wallet_label') {
        return (React.createElement(TableCell, { align: 'left', ref: ref },
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value || 'N/A')));
    }
    if (columnKey === 'fee') {
        return (React.createElement(TableCell, { align: 'left' },
            value && value !== '0' ? (React.createElement(NumericFormat, { displayType: 'text', value: new Decimal(value).toFixed(), thousandSeparator: envSettings.thousandDivider })) : ('-----'),
            "\u00A0",
            value ? (currencyFee ? currencyFee.toUpperCase() : null) : null));
    }
    if (columnKey === 'created' || columnKey === 'updated') {
        return React.createElement(TableCell, { align: 'left' }, value ? moment(value).format('YYYY-MM-DD H:mm') : 'n/a');
    }
    if (columnKey === 'tx_hash') {
        return (React.createElement(TableCell, { align: 'left', ref: ref },
            value ? React.createElement(CopyValue, { value: value }) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } },
                React.createElement(Link, { target: '_blank', rel: 'noreferrer', className: 'custody_hash_link', href: `${getExplorerURL(currency, currencies)}${value || ''}` }, value))));
    }
    if (columnKey === 'code') {
        return (React.createElement(TableCell, { align: 'left', ref: ref },
            value ? React.createElement(CopyValue, { value: value }) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } },
                React.createElement(Link, { href: '#', onClick: (e) => toggleDialog(e) }, value)),
            React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: 'md', fullWidth: true },
                React.createElement(DialogTitle, { id: 'form-dialog-title' }, "Actions"),
                React.createElement(DialogContent, null,
                    React.createElement(Main, { item: fullItem, allWithdraws: withdrawals })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: () => toggleDialog() }, "Close")))));
    }
    if (columnKey === 'wallet_code') {
        return (React.createElement(TableCell, { align: 'left', ref: ref },
            value ? React.createElement(CopyValue, { value: value }) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value)));
    }
    if (columnKey === 'customer_code') {
        if (walletsLoading) {
            return React.createElement(TableCell, { align: 'left' }, "loading...");
        }
        return (React.createElement(TableCell, { align: 'left', ref: ref },
            value && value.length === 36 ? React.createElement(CopyValue, { value: value }) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value || 'N/A'),
            "\u00A0"));
    }
    if (columnKey === 'state') {
        return (React.createElement(TableCell, { align: 'left' },
            React.createElement(Chip, { label: value || 'N/A', size: 'small', style: statusBadgeColor(statusBadgeData.stateBackgroundsWithdrawals[value], statusBadgeData.stateBordersWithdrawals[value], statusBadgeData.stateColorsWithdrawals[value]).badge }),
            "\u00A0",
            item.replaced_withdrawal_code ? (React.createElement(Chip, { label: 'Boosted', style: { background: '#32C6A1', border: 'none', color: '#fff' } })) : null));
    }
    if (columnKey === 'currency_code') {
        return React.createElement(TableCell, { align: 'left' }, value ? value.toUpperCase() : 'N/A');
    }
    return React.createElement(TableCell, { align: 'left' }, value || 'N/A');
};
export default WithdrawalsTableCell;

function isValidDate(dateString) {
    const date = Date.parse(dateString);
    return !isNaN(date);
}
function descendingComparator(a, b, orderBy) {
    let aParsed;
    let bParsed;
    const aVal = a[orderBy];
    const bVal = b[orderBy];
    if (typeof aVal === 'boolean' && typeof bVal === 'boolean') {
        aParsed = aVal ? 1 : 0;
        bParsed = bVal ? 1 : 0;
    }
    else if (typeof aVal === 'number' || !isNaN(Number(aVal))) {
        aParsed = parseFloat(aVal);
    }
    else if (typeof aVal === 'string' && isValidDate(aVal)) {
        aParsed = new Date(aVal);
    }
    else {
        aParsed = (aVal || '').toLowerCase();
    }
    if (typeof bVal === 'boolean' && typeof bVal === 'boolean') {
        bParsed = bVal ? 1 : 0;
    }
    else if (typeof bVal === 'number' || !isNaN(Number(bVal))) {
        bParsed = parseFloat(bVal);
    }
    else if (typeof bVal === 'string' && isValidDate(bVal)) {
        bParsed = new Date(bVal);
    }
    else {
        bParsed = (bVal || '').toLowerCase();
    }
    if (bParsed < aParsed)
        return -1;
    if (bParsed > aParsed)
        return 1;
    return 0;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0)
            return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
export { descendingComparator, getComparator, stableSort };

import * as types from '../actionTypes/runtimeSettingsActionTypes';
export const initialState = {
    settings: { force_execute_for_hedge_order: undefined },
    loading: false,
};
const runtimeSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_RUNTIME_SETTINGS:
            return Object.assign(Object.assign({}, state), { loading: true });
        case types.FETCH_RUNTIME_SETTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { settings: action.settings, loading: false });
        case types.FETCH_RUNTIME_SETTINGS_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false });
        case types.UPDATE_RUNTIME_SETTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { settings: action.settings, loading: false });
        default:
            return state;
    }
};
export default runtimeSettingsReducer;

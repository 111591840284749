import { Box, Button, Checkbox, Grid, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
const formSchema = yup
    .object({
    currency_code: yup.string().required().default(''),
    currency_ticker: yup.string().required().default(''),
    parent_currency_code: yup.string().default(''),
    precision: yup.number().required().default(0),
    label: yup.string().required().default(''),
    fireblocks_code: yup.string().required().default(''),
    kraken_ticker: yup.string().default(''),
    network_uuid: yup.string().required().default(''),
    token_standard: yup.string().oneOf(['base', 'erc-20', 'bep-20', 'trc-20', 'spl']).required().default('base'),
    contract_address: yup.string().default(''),
    price_feed_uuid: yup.string().required().default(''),
    elliptic_support_tx: yup.boolean().default(false),
    elliptic_support_address: yup.boolean().default(false),
    elliptic_code: yup.string().default(''),
    trmlabs_support_tx: yup.boolean().default(false),
    trmlabs_support_address: yup.boolean().default(false),
    trmlabs_code: yup.string().default(''),
    fee_value: yup.number().default(0),
    tangany_asset_id: yup.string().default(''),
    enabled: yup.boolean().default(true),
})
    .required();
const formConfigModel = {
    currency_code: { label: 'Currency Code', type: 'text', required: true },
    currency_ticker: { label: 'Currency Ticker', type: 'text', required: true },
    parent_currency_code: { label: 'Parent Currency', type: 'select', options: [], required: false },
    precision: { label: 'Precision', type: 'text', required: true },
    label: { label: 'Label', type: 'text', required: true },
    fireblocks_code: { label: 'Fireblocks Code', type: 'text', required: true },
    kraken_ticker: { label: 'Kraken Ticker', type: 'text', required: false },
    network_uuid: { label: 'Network', type: 'select', options: [], required: true },
    token_standard: {
        label: 'Token Standard',
        type: 'select',
        options: ['base', 'erc-20', 'bep-20', 'trc-20', 'spl'],
        required: true,
    },
    contract_address: { label: 'Contract Address', type: 'text', required: false },
    price_feed_uuid: { label: 'Price Feed', type: 'select', options: [], required: true },
    fee_value: { label: 'Fee Value', type: 'text', required: false },
    elliptic_support_tx: { label: 'Elliptic Support TX', type: 'checkbox', required: false },
    trmlabs_support_tx: { label: 'TRMLabs Support TX', type: 'checkbox', required: false },
    elliptic_support_address: { label: 'Elliptic Support Address', type: 'checkbox', required: false },
    trmlabs_support_address: { label: 'TRMLabs Support Address', type: 'checkbox', required: false },
    elliptic_code: { label: 'Elliptic Code', type: 'text', required: false },
    trmlabs_code: { label: 'TRMLabs Code', type: 'text', required: false },
    tangany_asset_id: { label: 'Tanganny Asset ID', type: 'text', required: false },
    enabled: { label: 'Enabled', type: 'checkbox', required: true },
};
const CustodyCurrencyCreate = (props) => {
    const { onClose, onSave, priceFeeds, networks, loading, currencies } = props;
    const { handleSubmit, control, register, trigger, setValue, getValues, formState: { errors }, } = useForm({
        defaultValues: formSchema.cast(formSchema.getDefault()),
        resolver: yupResolver(formSchema),
    });
    const [formConfig, setFormConfig] = useState(formConfigModel);
    useEffect(() => {
        setFormConfig((prev) => {
            const updated = Object.assign({}, prev);
            updated.price_feed_uuid.options = priceFeeds.map((m) => ({
                label: m.name,
                value: m.uuid,
            }));
            return updated;
        });
    }, [priceFeeds]);
    useEffect(() => {
        setFormConfig((prev) => {
            const updated = Object.assign({}, prev);
            formConfig.network_uuid.options = networks.map((m) => ({
                label: m.name,
                value: m.uuid,
            }));
            return updated;
        });
    }, [networks]);
    useEffect(() => {
        setFormConfig((prev) => {
            const updated = Object.assign({}, prev);
            formConfig.parent_currency_code.options = currencies
                .filter(c => c.token_standard === 'base' && c.network.can_have_child_wallets)
                .map((m) => ({
                label: m.label,
                value: m.code,
            }));
            return updated;
        });
    }, [currencies]);
    return (React.createElement(Box, { component: 'form', onSubmit: handleSubmit(onSave) },
        React.createElement(Grid, { container: true, spacing: 2, py: 2 }, Object.keys(formConfig).map((s, i) => {
            const key = s;
            const field = formConfig[key];
            return (React.createElement(Grid, { item: true, xs: 6, key: i },
                field.type === 'text' && (React.createElement(TextField, Object.assign({ fullWidth: true, InputLabelProps: { shrink: true }, error: !!errors[key], size: 'small', required: field.required, label: field.label, placeholder: field.label }, register(key), { onChange: (e) => {
                        setValue(key, e.target.value);
                        trigger(key);
                    } }))),
                field.type === 'select' && (React.createElement(Controller, { name: key, control: control, render: ({ field }) => {
                        var _a;
                        return (React.createElement(TextField, Object.assign({ fullWidth: true, select: true }, field, { size: 'small', error: !!errors[key], InputLabelProps: { shrink: true }, required: formConfig[key].required, label: formConfig[key].label, onChange: (e) => {
                                field.onChange(e.target.value);
                                setValue(key, e.target.value);
                                trigger(key);
                            } }), (_a = formConfig[key].options) === null || _a === void 0 ? void 0 : _a.map((o, i) => {
                            if (typeof o === 'string') {
                                return (React.createElement(MenuItem, { key: i, value: o }, o));
                            }
                            else {
                                return (React.createElement(MenuItem, { key: i, value: o.value }, o.label));
                            }
                        })));
                    } })),
                field.type === 'checkbox' && (React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
                    React.createElement(Controller, { name: key, control: control, render: ({ field }) => (React.createElement(Checkbox, Object.assign({}, field, { required: formConfig[key].required, checked: !!getValues(key), onChange: (e, checked) => {
                                field.onChange(checked);
                                setValue(key, checked);
                                trigger(key);
                            } }))) }),
                    React.createElement(Typography, { variant: 'body1' }, field.label)))));
        })),
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'flex-end', gap: 1 } },
            React.createElement(LoadingButton, { loading: loading, type: 'submit', variant: 'contained' }, "Add"),
            React.createElement(Button, { onClick: onClose }, "Cancel"))));
};
export default CustodyCurrencyCreate;

import React from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TransactionStatusDisplay from '../TransactionStatus';
import DetailsSectionBlock from '../Components/SectionDetailsBlock';
import LabeledTypography from '../Components/LabeledTypography';
import { format } from 'date-fns';
const TransactionRevertedDetails = ({ transaction, onClose }) => {
    return (React.createElement(Box, null,
        React.createElement(DialogTitle, null,
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                "Deposit Details",
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 } },
                    React.createElement(TransactionStatusDisplay, { status: transaction.state })))),
        React.createElement(DialogContent, null,
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 2 } },
                React.createElement(DetailsSectionBlock, { label: 'Deposit Overview' },
                    React.createElement(LabeledTypography, { value: transaction.depositor_iban, label: 'IBAN' }),
                    React.createElement(LabeledTypography, { value: transaction.depositor_name, label: 'Name' }),
                    React.createElement(LabeledTypography, { value: `${transaction.transaction_amount} ${transaction.transaction_currency}`, label: 'Amount' }),
                    React.createElement(LabeledTypography, { value: transaction.transaction_ref, label: 'Reference' }),
                    React.createElement(LabeledTypography, { value: format(new Date(transaction.transaction_date), 'dd-MM-yyyy HH:mm'), label: 'Date' })),
                React.createElement(DetailsSectionBlock, { label: 'System Info' },
                    React.createElement(Box, null,
                        React.createElement(LabeledTypography, { value: transaction.transaction_id, label: 'Transaction ID:', orientation: 'row' }),
                        React.createElement(LabeledTypography, { value: transaction.workflow_id, label: 'Workflow ID:', orientation: 'row' }),
                        React.createElement(LabeledTypography, { value: transaction.workflow_uuid, label: 'Workflow UUID:', orientation: 'row' })),
                    React.createElement(Box, null,
                        React.createElement(LabeledTypography, { value: format(new Date(transaction.internal_transaction_created_at) || null, 'dd-MM-yyyy HH:mm'), label: 'Created:', orientation: 'row' }),
                        transaction.updated_at ? (React.createElement(LabeledTypography, { value: format(new Date(transaction.updated_at) || null, 'dd-MM-yyyy HH:mm'), label: 'Updated:', orientation: 'row' })) : null,
                        transaction.completed_at ? (React.createElement(LabeledTypography, { value: format(new Date(transaction.completed_at) || null, 'dd-MM-yyyy HH:mm'), label: 'Completed:', orientation: 'row' })) : null,
                        transaction.external_transfer_reversal_id ? (React.createElement(LabeledTypography, { value: transaction.external_transfer_reversal_id, label: 'External Id:', orientation: 'row' })) : null)))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { variant: 'outlined', color: 'inherit', size: 'small', onClick: onClose }, "Close"))));
};
export default TransactionRevertedDetails;

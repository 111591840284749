import React, { useState } from 'react';
import { Box, Dialog, Button, TextField, DialogTitle, DialogContent, DialogActions } from '@mui/material';
const TwoFAModal = ({ open, codes, handleFunction, toggleFunc, loading }) => {
    const [twoFA, setTwoFA] = useState('');
    const sendRequest = (e) => {
        e.preventDefault();
        const payload = {
            codes,
            totp_code: twoFA,
        };
        handleFunction(payload);
        setTwoFA('');
    };
    const onTwoFAChange = (evt) => {
        const { value } = evt.target;
        setTwoFA(value);
    };
    const handleChange = (evt) => {
        const { value } = evt.target;
        if (isNaN(Number(value)) || value.length > 6) {
            return;
        }
        onTwoFAChange(evt);
    };
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: 'sm', fullWidth: true },
        React.createElement("form", { onSubmit: sendRequest },
            React.createElement(DialogTitle, { id: 'form-dialog-title' }, "Approve addresses"),
            React.createElement(DialogContent, null,
                React.createElement(Box, { mb: 2 }, "Please provide TOTP password to proceed"),
                React.createElement(Box, { display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 2 },
                    React.createElement(TextField, { id: 'totp', type: 'text', name: 'totp', variant: 'outlined', size: 'small', value: twoFA, autoFocus: true, label: 'TOTP', onChange: handleChange, inputProps: { pattern: '^[0-9]{6}$' } }))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { color: 'primary', variant: 'contained', type: 'submit', disabled: twoFA.length !== 6 }, "Approve"),
                React.createElement(Button, { variant: 'outlined', onClick: toggleFunc }, "Cancel")))));
};
export default TwoFAModal;

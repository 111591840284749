import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import buildUrl from 'build-url';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import { InfoItem, Delimiter, OrderInfo, BalanceLabel, BalanceValue, InfoItemLabel, InfoItemValue, OrderBalances, } from '../../MergedOrderList/styling/styledComponents';
import UsersService from '../../../../../../services/usersService';
import Messages from '../../../../../../shared/helpers/errorMessages';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
import showNotification from '../../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import getUserEmailByCode from '../../../../../../shared/helpers/getUserEmailByCode';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { Dialog, DialogContent } from '@mui/material';
import DialogHeader from '../../../../../../shared/components/DialogHeader';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
const envSettings = getEnvSettings();
const OrderInfoModal = ({ open, toggleFunc, item, customer, features, code, total, selectedClient, masterTenant, theme, }) => {
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [users, setUsers] = useState([]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const toggleModal = () => {
        toggleFunc();
    };
    const fetchUsers = (clientCode, cancelToken) => {
        const usersUrlOptions = {
            queryParams: {
                limit: '1000',
                search: '',
                client_code: clientCode,
            },
            path: '/users',
        };
        const endpointUrl = buildUrl('', usersUrlOptions);
        const usersService = new UsersService({
            url: endpointUrl,
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoadingUsers(true);
        usersService.makeRequest()
            .then((responseData) => {
            if (responseData) {
                setLoadingUsers(false);
                const { records } = responseData;
                setUsers(records);
            }
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoadingUsers(false);
            const message = composeErrorMessage(e, Messages.USERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const statusesMap = {
        Open: 'Open',
        Filled: 'Filled',
        Prepared: 'Prepared',
        Canceled: 'Canceled',
        Rejected: 'Rejected',
        ToCancel: 'ToCancel',
        CanceledPartiallyFilled: 'CanceledPartiallyFilled',
    };
    const date = item.finished ? (new Date(item.finished)).toLocaleDateString() : null;
    const time = (new Date(item.finished)).toLocaleTimeString();
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        const codeClient = (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || '';
        if (masterTenant) {
            fetchUsers(codeClient, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [selectedClient]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: open, fullWidth: true, maxWidth: "sm" },
            React.createElement(DialogHeader, { title: 'Order details', toggleFunc: toggleModal }),
            React.createElement(React.Fragment, null,
                React.createElement(DialogContent, null,
                    React.createElement(OrderInfo, null,
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Status"),
                            React.createElement(InfoItemValue, null, statusesMap[item.status])),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Internal ID"),
                            React.createElement(InfoItemValue, null, item.code)),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Exchange ID"),
                            React.createElement(InfoItemValue, null, item.external_code)),
                        date && (React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Finished"),
                            React.createElement(InfoItemValue, null,
                                date,
                                " ",
                                time))),
                        item.reference_id && (React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Reference Id"),
                            React.createElement(InfoItemValue, null, item.reference_id))),
                        React.createElement(Delimiter, null),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Customer code"),
                            React.createElement(InfoItemValue, null, code)),
                        getUserEmailByCode(users, item.creator_code) ? (React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Creator"),
                            React.createElement(InfoItemValue, null, loadingUsers
                                ? React.createElement(CircularProgress, { size: "33px" })
                                : getUserEmailByCode(users, item.creator_code)))) : null,
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Created"),
                            React.createElement(InfoItemValue, null, item.created ? moment(item.created).format('YYYY-MM-DD H:mm:ss') : 'n/a')),
                        customer && (React.createElement(Permissions, { features: features, permissions: ['customer_details.read'] },
                            React.createElement(React.Fragment, null,
                                React.createElement(InfoItem, null,
                                    React.createElement(InfoItemLabel, null, "Customer name"),
                                    React.createElement(InfoItemValue, null, customer.company_name)),
                                customer.ibans ? customer.ibans.map(ibanItem => (React.createElement(InfoItem, { key: ibanItem.iban },
                                    React.createElement(InfoItemLabel, null,
                                        "IBAN(",
                                        ibanItem.currency,
                                        ")"),
                                    React.createElement(InfoItemValue, null,
                                        ibanItem.iban,
                                        " ",
                                        React.createElement("span", null, ibanItem.is_enabled ? '' : ' (disabled)'))))) : null,
                                customer.bfid
                                    && (React.createElement(InfoItem, null,
                                        React.createElement(InfoItemLabel, null, "BFID"),
                                        React.createElement(InfoItemValue, null, customer.bfid))),
                                React.createElement(InfoItem, null,
                                    React.createElement(InfoItemLabel, null, "VAT"),
                                    React.createElement(InfoItemValue, null, customer.vat)),
                                React.createElement(InfoItem, null,
                                    React.createElement(InfoItemLabel, null, "Address"),
                                    React.createElement(InfoItemValue, null, customer.address)),
                                React.createElement(InfoItem, null,
                                    React.createElement(InfoItemLabel, null, "Zip Code"),
                                    React.createElement(InfoItemValue, null, customer.zip_code)),
                                React.createElement(InfoItem, null,
                                    React.createElement(InfoItemLabel, null, "City"),
                                    React.createElement(InfoItemValue, null, customer.city)),
                                React.createElement(InfoItem, null,
                                    React.createElement(InfoItemLabel, null, "Country"),
                                    React.createElement(InfoItemValue, null, customer.country)),
                                React.createElement(InfoItem, null,
                                    React.createElement(InfoItemLabel, null, "Name contact"),
                                    React.createElement(InfoItemValue, null, customer.contact_person_name)),
                                React.createElement(InfoItem, null,
                                    React.createElement(InfoItemLabel, null, "Phone number"),
                                    React.createElement(InfoItemValue, null, customer.phone_number)))))),
                    React.createElement(OrderBalances, null,
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Action"),
                            React.createElement(BalanceValue, null, item.action)),
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Order Size"),
                            React.createElement(BalanceValue, null, `${item.amount} ${item.market_item.base}`)),
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Executed"),
                            React.createElement(BalanceValue, null, `${item.executed.amount} ${item.market_item.base}`)),
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Price per"),
                            React.createElement(BalanceValue, null, `${item.executed.price} ${item.market_item.quote}`)),
                        React.createElement(Delimiter, null),
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Subtotal"),
                            React.createElement(BalanceValue, null,
                                React.createElement(NumericFormat, { displayType: "text", value: item.executed.value, thousandSeparator: envSettings.thousandDivider }),
                                "\u00A0",
                                item.market_item.quote)),
                        item.executed.bank_fee === '0' ? null : (React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Bank fee"),
                            React.createElement(BalanceValue, null, `${item.executed.bank_fee} ${item.market_item.quote}`))),
                        item.executed.broker_fee === '0' ? null : (React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Broker fee"),
                            React.createElement(BalanceValue, null, `${item.executed.broker_fee} ${item.market_item.quote}`))),
                        React.createElement(Delimiter, null),
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Total"),
                            React.createElement(BalanceValue, null,
                                React.createElement(NumericFormat, { value: total, displayType: "text", thousandSeparator: envSettings.thousandDivider }),
                                "\u00A0",
                                item.market_item.quote))))))));
};
const mapStateToProps = (state) => ({
    theme: state.theme,
    selectedClient: getSelectedClientInputValue(state),
    masterTenant: state.client.masterTenant,
});
export default connect(mapStateToProps)(OrderInfoModal);

import React, { useEffect, useReducer, useState } from 'react';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import { Box, Button, Checkbox, CircularProgress, Container } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { PanelContainer, MaterialPopoverWrap, WalletsClientContainer } from '../../styling/style';
import { SpinnerContainer } from '../../../Admin/styling/style';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import WithdrawalsCellActions from '../Wallets/WithdrawalsCellActions';
import WithdrawalsTableCell from './WithdrawalsTableCell';
import TwoFASignModal from './TwoFASignModal';
import CustodyClientWithdrawalsService from '../../../../../services/custodyWithdrawalsService';
import showNotification from '@shared/helpers/notifications';
import { composeErrorMessage } from '@shared/helpers/interceptors';
import Messages from '@shared/helpers/errorMessages';
const init = (initValue) => ({
    bulk: initValue,
});
var BulkActionType;
(function (BulkActionType) {
    BulkActionType["INIT"] = "INIT";
    BulkActionType["UPDATE"] = "UPDATE";
    BulkActionType["SET_ALL"] = "SET_ALL";
})(BulkActionType || (BulkActionType = {}));
const updateItemInArray = (items, item) => {
    const addressIndex = items.findIndex((a) => a.code === item.code);
    return items.map((address, index) => {
        if (index !== addressIndex) {
            return address;
        }
        const mergedItem = Object.assign(Object.assign({}, address), { value: item.value });
        return Object.assign(Object.assign({}, address), mergedItem);
    });
};
const reducer = (state, action) => {
    switch (action.type) {
        case BulkActionType.SET_ALL:
            return {
                bulk: state.bulk.map((b) => Object.assign({}, b, { value: true })),
            };
        case BulkActionType.UPDATE: {
            const newArr = updateItemInArray(state.bulk, action.item);
            return { bulk: newArr };
        }
        case BulkActionType.INIT:
            return init(action.payload);
        default:
            return state;
    }
};
/* eslint-disable react/jsx-indent */
const Withdrawals = ({ totalWithdrawals, loading, admin, pageWithdrawals, onChangePageW, sortBy, columns, features, currencies, handleColumnsClick, columnsOpen, anchorColumns, handleColumnsClose, columnsToRender, hideShowColumn, withdrawals, maxHeight, withdrawalsToRender, rowsPerPage, sortDirection, onSortHandle, fetchAllWithDrawals, walletsLoading, clientInfo, initialValue, }) => {
    const colsW = columns.filter((c) => c.id.includes('withdrawalsApproveColumns'));
    const [codes, setCodes] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialValue, init);
    const bulkSign = (payload, operationType) => {
        const url = `/custody/withdrawals/bulk_${operationType}`;
        const service = new CustodyClientWithdrawalsService({
            url,
            data: payload,
            method: 'post',
        });
        service
            .makeRequest()
            .then(() => {
            toggleModal();
            fetchAllWithDrawals();
            showNotification({
                message: 'All withdrawals have been successfully signed',
                color: 'success',
            });
        })
            .catch((e) => {
            toggleModal();
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.WITHDRAWALS_BULK_SIGN);
            showNotification({
                message,
                color: 'error',
            });
        });
    };
    const setAllBulk = () => {
        dispatch({ type: BulkActionType.SET_ALL });
    };
    const updateBulk = (val) => {
        dispatch({ type: BulkActionType.UPDATE, item: val });
    };
    const initBulk = (payload) => {
        dispatch({ type: BulkActionType.INIT, payload });
    };
    const handleBulkChange = (evt, code) => {
        const { checked } = evt.target;
        const item = {
            code,
            value: checked,
        };
        updateBulk(item);
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const handleSelectAll = (evt, initial) => {
        const { checked } = evt.target;
        setSelectAll(checked);
        if (checked) {
            setAllBulk();
        }
        else {
            initBulk(initial);
        }
    };
    useEffect(() => {
        if (initialValue.length) {
            initBulk(initialValue);
        }
    }, [initialValue]);
    useEffect(() => {
        if (state.bulk.length) {
            const joined = state.bulk.filter((b) => b.value).map((b) => b.code);
            setCodes(joined);
            const isChecked = state.bulk.find((b) => b.value);
            if (isChecked) {
                setDisableButton(true);
            }
            else {
                setDisableButton(false);
            }
        }
    }, [state]);
    return (React.createElement(PanelContainer, { className: 'blocks_wrapp_body_style' }, loading ? (React.createElement(SpinnerContainer, null,
        React.createElement(CircularProgress, { color: 'primary' }))) : (React.createElement(React.Fragment, null,
        React.createElement(WalletsClientContainer, null,
            modalOpen ? (React.createElement(TwoFASignModal, { codes: codes, loading: false, open: modalOpen, toggleFunc: toggleModal, handleFunction: bulkSign })) : null,
            React.createElement("div", null,
                React.createElement("span", { className: 'custody_clients_add_new', style: { top: '0px' } },
                    React.createElement(Button, { size: 'small', color: 'primary', variant: 'outlined', disabled: !disableButton, onClick: toggleModal }, "Sign Selected")),
                React.createElement(IconButton, { onClick: handleColumnsClick, size: 'large' },
                    React.createElement(ViewColumnIcon, null)),
                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    }, transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: 'nav', "aria-label": 'more actions', className: 'mui_table_settings_wrap' }, colsW.map((col) => (React.createElement(ListItem, { key: `withdrawals_approve_${col.id}` },
                            React.createElement(ListItemText, { primary: col.title }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(GreenSwitch, { edge: 'end', size: 'small', color: 'default', onChange: () => hideShowColumn(col.id), checked: !!columnsToRender.find((c) => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
        withdrawalsToRender.length === 0 ? (React.createElement(Container, null,
            React.createElement(Alert, { severity: 'info', variant: 'outlined' }, "No items found"))) : (React.createElement(React.Fragment, null,
            React.createElement(TableContainer, { style: { maxHeight } },
                React.createElement(Table, { size: 'small', stickyHeader: true, "aria-label": 'sticky table' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            columnsToRender.map((column) => {
                                if (column.key === 'requested_amount') {
                                    return (React.createElement(TableCell, { align: 'right', key: column.key, style: { minWidth: column.minWidth } },
                                        React.createElement(Box, { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1 },
                                            React.createElement(Box, null,
                                                React.createElement("label", { htmlFor: 'selectAll', className: 'checkbox-btn', style: { marginBottom: '5px' } },
                                                    React.createElement(Checkbox, { id: 'selectAll', name: 'selectAll', checked: selectAll, onChange: (e) => handleSelectAll(e, initialValue) }))),
                                            React.createElement(Box, { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 },
                                                React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title)))));
                                }
                                return (React.createElement(TableCell, { align: 'left', key: column.key, style: { minWidth: column.minWidth } }, column.key !== 'creator' &&
                                    column.key !== 'currency_code' &&
                                    column.key !== 'address' &&
                                    column.key !== 'address_label' &&
                                    column.key !== 'updated_at' ? (React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title)) : (column.title)));
                            }),
                            React.createElement(TableCell, { key: 'actions', align: 'right', className: 'tokens_table_cell_width' }, "Actions"))),
                    React.createElement(TableBody, null, withdrawalsToRender.map((w) => (React.createElement(TableRow, { hover: true, role: 'checkbox', tabIndex: -1, key: w.code },
                        columnsToRender.map((column, i) => {
                            const key = column.key;
                            const value = key ? w[key] : undefined;
                            return (React.createElement(WithdrawalsTableCell, { key: i, item: w, value: value, columnKey: key, bulk: state.bulk, handleBulkChange: handleBulkChange, currencies: currencies, withdrawals: withdrawals, currency: w.currency_code, walletsLoading: walletsLoading, currencyFee: w.fee_currency_code }));
                        }),
                        React.createElement(WithdrawalsCellActions, { item: w, admin: admin, features: features, currencies: currencies, clientInfo: clientInfo, fetchAllWithDrawals: fetchAllWithDrawals }))))))),
            React.createElement(TablePagination, { component: 'div', page: pageWithdrawals, count: totalWithdrawals, rowsPerPage: rowsPerPage, onPageChange: onChangePageW, rowsPerPageOptions: [maxHeight] })))))));
};
export default Withdrawals;

import { Box, Dialog, InputAdornment, Tab, Tabs, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DLTFTable from '@shared/components/table/DLTTableComponent';
import { CELL_TYPE } from '@shared/components/table/DLTTableModel';
import React, { useEffect, useState } from 'react';
import DepositDetails from './DepositDetails';
import { TransactionActivity, TransferStatus } from './transfersModel';
import axios from 'axios';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
const tableCols = [
    { id: 'depositor_name', label: 'Name', sortable: true, width: 20 },
    { id: 'transaction_amount', label: 'Amount', width: 20, sortable: true, type: CELL_TYPE.NUMERIC },
    { id: 'depositor_iban', label: 'IBAN', width: 20, type: CELL_TYPE.ID },
    { id: 'transaction_ref', label: 'Reference', width: 20, type: CELL_TYPE.ID },
    { id: 'transaction_date', label: 'Date', sortable: true, width: 20, align: 'left', type: CELL_TYPE.DATE_TIME },
    { id: 'workflow_id', label: 'Workflow ID', width: 20, type: CELL_TYPE.ID },
    { id: 'workflow_uuid', label: 'Workflow UUID', width: 20, type: CELL_TYPE.ID },
];
const mapTransfers = (transfers, searchString) => {
    return transfers
        .filter((t) => {
        var _a;
        let result = false;
        for (const prop in t) {
            const search = (_a = t[prop]) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().includes(searchString.toLowerCase());
            if (search) {
                result = true;
            }
        }
        return result;
    })
        .map((t) => {
        return Object.assign(Object.assign({}, t), { id: t.workflow_uuid, state: t.activity === TransactionActivity.BankTransferValidateCustomerActivity
                ? TransferStatus.UserInputReference
                : t.activity === TransactionActivity.BankTransferValidateDepositActivity
                    ? TransferStatus.UserInputDeposit
                    : t.state === TransferStatus.Completed && t.external_transfer_reversal_id
                        ? TransferStatus.Reverted
                        : t.state });
    });
};
const WidgetFIATDeposits = () => {
    const [dialog, setDialog] = useState(true);
    const [total, setTotal] = useState(10);
    const [tab, setTab] = useState(TransferStatus.UserInputDeposit);
    const [selectedTransaction, setSelectedTransaction] = useState();
    const [transactions, setTransactions] = useState([]);
    const [mappedTransactions, setMappedTransactions] = useState([]);
    const [searchString, setSearchString] = useState('');
    const handleDialogClose = (config) => {
        setSelectedTransaction(undefined);
        const state = tab === TransferStatus.UserInputDeposit ? 'user-input-required' : tab;
        if (config.reload) {
            getTransfers(state);
        }
    };
    const handleDespositDetails = (id) => {
        setDialog(!dialog);
        const transaction = mappedTransactions.find((t) => t.workflow_uuid === id);
        if (transaction) {
            setSelectedTransaction(transaction);
        }
    };
    const handleTabChange = (value) => {
        setTab(value);
    };
    const getTransfers = (state) => {
        return axios.get(`bank-transfer?state=${state}`, {
            baseURL: getEnvSettings().adminApiUrl,
        });
    };
    useEffect(() => {
        const state = tab === TransferStatus.UserInputDeposit
            ? 'user-input-required'
            : tab === TransferStatus.Reverted
                ? 'completed'
                : tab;
        getTransfers(state).then((response) => {
            const data = response.data.transfers;
            if (tab === TransferStatus.Reverted) {
                const filtered = data.filter((m) => {
                    return !!m.external_transfer_reversal_id;
                });
                setTransactions(filtered);
                setTotal(filtered.length);
                return;
            }
            if (tab === TransferStatus.Completed) {
                const filtered = data.filter((m) => {
                    return !m.external_transfer_reversal_id;
                });
                setTransactions(filtered);
                setTotal(filtered.length);
                return;
            }
            setTransactions(data);
        });
    }, [tab]);
    useEffect(() => {
        if (transactions) {
            const mapped = mapTransfers(transactions, searchString);
            setMappedTransactions(mapped);
            setTotal(mapped.length);
        }
    }, [searchString, transactions]);
    return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 2 } },
        selectedTransaction ? (React.createElement(Dialog, { open: !!selectedTransaction, fullWidth: true, maxWidth: 'md', onClose: () => setSelectedTransaction(undefined) },
            React.createElement(DepositDetails, { transaction: selectedTransaction, onClose: (config) => handleDialogClose(config) }))) : null,
        React.createElement(Box, { sx: { display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between' } },
            React.createElement(Box, null,
                React.createElement(Tabs, { value: tab, onChange: (e, value) => handleTabChange(value) },
                    React.createElement(Tab, { label: 'action required', value: TransferStatus.UserInputDeposit }),
                    React.createElement(Tab, { label: 'failed', value: TransferStatus.Failed }),
                    React.createElement(Tab, { label: 'reversed', value: TransferStatus.Reverted }),
                    React.createElement(Tab, { label: 'completed', value: TransferStatus.Completed }))),
            React.createElement(Box, null,
                React.createElement(TextField, { placeholder: 'Search', value: searchString, onChange: (e) => setSearchString(e.target.value), size: 'small', InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: 'end' },
                            React.createElement(SearchIcon, null))),
                    } }))),
        React.createElement(DLTFTable, { columns: tableCols, total: total, data: mappedTransactions, onRowClick: handleDespositDetails, pagination: true, rowsPerPage: 10, defaultOrder: 'desc', defaultOrderProp: 'transaction_date', internalSorting: true })));
};
export default WidgetFIATDeposits;

import { all } from 'redux-saga/effects';
import { ordersSaga } from './orderBookSaga';
import { placeOrderSagas } from './placeOrderSaga';
import { clientSagas } from './clientSaga';
import { customerSearchSagas } from './customersSaga';
import { widgetSettingsSaga } from './widgetSettingsSaga';
import { workspacesSaga } from './workspacesSaga';
import { adminSagas } from './adminSaga';
import { userSagas } from './userSaga';
import { currenciesSaga } from './currenciesSaga';
import { runtimeSettingsSaga } from './runtimeSettingsSaga';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default function* rootSaga() {
    yield all([
        ...clientSagas,
        ...currenciesSaga,
        ...userSagas,
        ...ordersSaga,
        ...placeOrderSagas,
        ...customerSearchSagas,
        ...widgetSettingsSaga,
        ...workspacesSaga,
        ...adminSagas,
        ...runtimeSettingsSaga,
    ]);
}

import React from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import LabeledTypography from '../Components/LabeledTypography';
import DetailsSectionBlock from '../Components/SectionDetailsBlock';
import TransactionStatusDisplay from '../TransactionStatus';
import { format } from 'date-fns';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UUID_REGEX } from '@shared/constants/UUIDRegex';
const formSchema = yup
    .object({
    reference: yup.string().matches(UUID_REGEX).required().default(''),
})
    .required();
const TransactionUserInputReferenceDetails = ({ transaction, onClose, onUpdate, onRejectCustomer, }) => {
    const { handleSubmit, register, trigger, setValue, formState: { errors }, } = useForm({
        defaultValues: formSchema.cast(formSchema.getDefault()),
        resolver: yupResolver(formSchema),
    });
    const handleReject = () => {
        onRejectCustomer();
    };
    const handleClose = () => {
        onClose();
    };
    return (React.createElement(Box, null,
        React.createElement(DialogTitle, null,
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                "Deposit Details",
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 } },
                    React.createElement(TransactionStatusDisplay, { status: transaction.state })))),
        React.createElement(DialogContent, null,
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 2 } },
                React.createElement(DetailsSectionBlock, { label: 'Deposit Overview' },
                    React.createElement(LabeledTypography, { value: transaction.depositor_iban, label: 'IBAN' }),
                    React.createElement(LabeledTypography, { value: transaction.depositor_name, label: 'Name' }),
                    React.createElement(LabeledTypography, { value: `${transaction.transaction_amount} ${transaction.transaction_currency}`, label: 'Amount' }),
                    React.createElement(LabeledTypography, { value: transaction.transaction_ref, label: 'Reference', color: 'warning.main' }),
                    React.createElement(LabeledTypography, { value: format(new Date(transaction.transaction_date), 'dd-MM-yyyy HH:mm'), label: 'Date' })),
                React.createElement(DetailsSectionBlock, { label: 'System Info' },
                    React.createElement(Box, null,
                        React.createElement(LabeledTypography, { value: transaction.transaction_id, label: 'Transaction ID:', orientation: 'row' }),
                        React.createElement(LabeledTypography, { value: transaction.workflow_id, label: 'Workflow ID:', orientation: 'row' }),
                        React.createElement(LabeledTypography, { value: transaction.workflow_uuid, label: 'Workflow UUID:', orientation: 'row' }))),
                React.createElement(DetailsSectionBlock, { label: 'Action', color: 'warning.main' },
                    React.createElement(Box, { component: 'form', onSubmit: handleSubmit(onUpdate), sx: { display: 'flex', alignItems: 'center', width: '100%', gap: 1 } },
                        React.createElement(TextField, Object.assign({ sx: { width: '36ch' }, InputLabelProps: { shrink: true }, error: !!errors['reference'], size: 'small', required: true, label: 'Customer UUID' }, register('reference'), { onChange: (e) => {
                                setValue('reference', e.target.value);
                                trigger('reference');
                            } })),
                        React.createElement(Button, { size: 'small', variant: 'contained', type: 'submit' }, "Update"))),
                React.createElement(DetailsSectionBlock, { label: 'Error', color: 'error.main' },
                    React.createElement(Typography, { variant: 'body2' }, transaction.error_message || 'No error despcription available')))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { variant: 'contained', color: 'error', size: 'small', onClick: handleReject }, "Reject"),
            React.createElement(Button, { variant: 'outlined', color: 'inherit', size: 'small', onClick: handleClose }, "Close"))));
};
export default TransactionUserInputReferenceDetails;

import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
const DetailsSectionBlock = (props) => {
    const theme = useTheme();
    return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', alignItems: 'start' } },
        React.createElement(Box, { sx: {
                backgroundColor: props.color || theme.palette.secondary.light,
                flex: 0,
                px: 2,
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
            } },
            React.createElement(Typography, { variant: 'overline' }, props.label)),
        React.createElement(Box, { sx: {
                display: 'flex',
                flexWrap: 'wrap',
                backgroundColor: `${theme.palette.secondary.main}50`,
                p: 2,
                gap: 3,
                width: '100%',
            } }, props.children)));
};
export default DetailsSectionBlock;

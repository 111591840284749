export var ASSETS;
(function (ASSETS) {
    ASSETS["ADA"] = "ada";
    ASSETS["BNT"] = "bnt";
    ASSETS["ENJ"] = "enj";
    ASSETS["ALGO"] = "algo";
    ASSETS["ANKR"] = "ankr";
    ASSETS["ATOM"] = "atom";
    ASSETS["AVAX"] = "avax";
    ASSETS["BCH"] = "bch";
    ASSETS["BNB"] = "bnb_bsc";
    ASSETS["BTC"] = "btc";
    ASSETS["CELO"] = "celo";
    ASSETS["DOT"] = "dot";
    ASSETS["ETC"] = "etc";
    ASSETS["ETH"] = "eth";
    ASSETS["SOL"] = "sol";
    ASSETS["XLM"] = "xlm";
    ASSETS["XRP"] = "xrp";
    ASSETS["INCH_1"] = "1inch";
    ASSETS["AAVE"] = "aave";
    ASSETS["ARB"] = "arb_arb";
    ASSETS["AXS"] = "axs";
    ASSETS["BAL"] = "bal";
    ASSETS["POL"] = "pol";
    ASSETS["APE"] = "ape";
    ASSETS["API3"] = "api3";
    ASSETS["BAND"] = "band";
    ASSETS["BAT"] = "bat";
    ASSETS["BICO"] = "bico";
    ASSETS["CHZ"] = "chz";
    ASSETS["COMP"] = "comp";
    ASSETS["CRV"] = "crv";
    ASSETS["CTSI"] = "ctsi";
    ASSETS["DYDX"] = "dydx";
    ASSETS["ENS"] = "ens";
    ASSETS["FET"] = "fet";
    ASSETS["FTM"] = "ftm_fantom";
    ASSETS["GALA"] = "gala";
    ASSETS["GRT"] = "grt";
    ASSETS["IMX"] = "imx";
    ASSETS["KNC"] = "knc";
    ASSETS["LINK"] = "link";
    ASSETS["LPT"] = "lpt";
    ASSETS["LRC"] = "lrc";
    ASSETS["MANA"] = "mana";
    ASSETS["MKR"] = "mkr";
    ASSETS["OGN"] = "ogn";
    ASSETS["OXT"] = "oxt";
    ASSETS["AMP"] = "amp";
    ASSETS["CRO"] = "cro";
    ASSETS["FTT"] = "ftt";
    ASSETS["LTC"] = "ltc";
    ASSETS["ALICE"] = "alice";
    ASSETS["DOGE"] = "doge";
    ASSETS["FLR"] = "flr";
    ASSETS["IOTA"] = "iota_bsc";
    ASSETS["SKL"] = "skl";
    ASSETS["SRM"] = "srm_sol";
    ASSETS["XTZ"] = "xtz";
    ASSETS["QNT"] = "qnt";
    ASSETS["REN"] = "ren";
    ASSETS["SAND"] = "sand";
    ASSETS["SHIB"] = "shib";
    ASSETS["SNX"] = "snx";
    ASSETS["STORJ"] = "storj";
    ASSETS["SUSHI"] = "sushi";
    ASSETS["TRX"] = "trx";
    ASSETS["UMA"] = "uma";
    ASSETS["UNI"] = "uni";
    ASSETS["YFI"] = "yfi";
})(ASSETS || (ASSETS = {}));

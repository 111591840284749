import { call, put, takeLatest } from 'redux-saga/effects';
import Messages from '../shared/helpers/errorMessages';
import * as runtimeSettingsActions from '../redux/actions/runtimeSettingsActions';
import * as types from '../redux/actionTypes/runtimeSettingsActionTypes';
import { composeAnyErrorMessage } from '../shared/helpers/interceptors';
import { OPEN_ERROR_NOTICE } from '../redux/actionTypes/apiErrorsActionTypes';
function* fetchSettings() {
    try {
        const settings = yield call(runtimeSettingsActions.fetchSettings);
        yield put({
            type: types.FETCH_RUNTIME_SETTINGS_SUCCESS,
            settings,
        });
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.RUNTIME_SETTINGS_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.FETCH_RUNTIME_SETTINGS_FAIL, message });
    }
}
function* updateSettings(action) {
    try {
        if (action.type === types.UPDATE_RUNTIME_SETTINGS) {
            const settings = yield call(runtimeSettingsActions.updateSettings, action.settings);
            yield put({
                type: types.UPDATE_RUNTIME_SETTINGS_SUCCESS,
                settings,
            });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.RUNTIME_SETTINGS_UPDATE);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.UPDATE_RUNTIME_SETTINGS_FAIL, message });
    }
}
export const runtimeSettingsSaga = [
    takeLatest(types.FETCH_RUNTIME_SETTINGS, fetchSettings),
    takeLatest(types.UPDATE_RUNTIME_SETTINGS, updateSettings),
];

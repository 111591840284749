import React, { useState, useEffect, useCallback, useContext } from 'react';
import List from '@mui/material/List';
import { Box, Table, Alert, Popover, ListItem, TableRow, TableBody, TableCell, TableHead, Container, IconButton, TableContainer, TableSortLabel, TablePagination, CircularProgress, ListItemSecondaryAction, } from '@mui/material';
import axios from 'axios';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { connect, useDispatch } from 'react-redux';
import { PanelContainer, MaterialPopoverWrap, WalletsClientContainer } from '../../styling/style';
import { AddNote, WalletLabel, WalletMigrate, UpdateBalance, WalletEnableDisable, ConnectedWalletCustomer, } from './components/WalletsActions';
import { CUSTODY_WALLETS_UPDATE, LOADING_WALLETS_UPDATE } from '../../../../../redux/actionTypes/custodyActionTypes';
import WalletModal from './WalletModal';
import WalletsFilters from './WalletsFilters';
import WalletTableCell from './WalletTableCell';
import { SpinnerContainer } from '../../../Admin/styling/style';
import WalletsCellActions from './WalletsCellActions';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import { RatesContext } from '../../../../../providers/RatesProvider';
import showNotification from '../../../../../shared/helpers/notifications';
import CustodyWalletService from '../../../../../services/custodyWalletService';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getWidgetColumns, getWalletsToRender } from '../../../../../redux/selectors/custodySelectors';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
/* eslint-disable @typescript-eslint/restrict-template-expressions, prefer-destructuring,
  @typescript-eslint/no-explicit-any, no-nested-ternary, camelcase, react/jsx-indent */
export const cTypes = {
    base: '',
    erc20: 'ERC20',
    bep20: 'BEP20',
};
var FILTER_OPTIONS;
(function (FILTER_OPTIONS) {
    FILTER_OPTIONS["created_at"] = "created";
    FILTER_OPTIONS["accounting_type"] = "type";
    FILTER_OPTIONS["label"] = "label";
    FILTER_OPTIONS["deposit_address"] = "address";
    FILTER_OPTIONS["currency_code"] = "currency";
    FILTER_OPTIONS["balance_total"] = "total";
    FILTER_OPTIONS["usd_locked"] = "locked";
    FILTER_OPTIONS["balance_available"] = "available";
})(FILTER_OPTIONS || (FILTER_OPTIONS = {}));
const WidgetWallets = ({ clientInfo, features, selectedClient, allWallets, theme, columns, wallets, walletsWidgetHeight, masterTenant, }) => {
    const ctx = useContext(RatesContext);
    const [loading, setLoading] = useState(false);
    const [maxHeight, setMaxHeight] = useState(480);
    const [clientCode, setClientCode] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [hideZero, setDisplayZero] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [withMigrated, setWithMigrated] = useState(false);
    const [currentWalletPage, setCurrentWalletPage] = useState(1);
    const [addWalletLoading, setAddWalletLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [filtersState, setFiltersState] = useState([]);
    const [sortDirection, setSortDirection] = useState('desc');
    const [walletsToRender, setWalletsToRender] = useState([]);
    const [walletsFiltered, setWalletsFiltered] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [sortBy, setSortBy] = useState('created_at');
    const [columnsToRender, setColumnsToRender] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const updateWalletsItems = (records, item) => {
        const itemIndex = records.findIndex((w) => w.code === item.code);
        return records.map((walletItem, index) => {
            if (index !== itemIndex) {
                return walletItem;
            }
            const mergedItem = Object.assign(Object.assign({}, walletItem), { note: item.note, label: item.label, state: item.state, is_migrated: item.is_migrated, customer_code: item.customer_code });
            return Object.assign(Object.assign({}, walletItem), mergedItem);
        });
    };
    const cellActions = {
        actions: {
            updateLabel: (item) => {
                const updatedWallets = updateWalletsItems(wallets, item);
                dispatch({ type: CUSTODY_WALLETS_UPDATE, wallets: updatedWallets });
            },
            selectCustomer: (item) => {
                const updatedRecords = updateWalletsItems(wallets, item);
                dispatch({ type: CUSTODY_WALLETS_UPDATE, wallets: updatedRecords });
            },
            adminUnAssign: (item) => {
                const updatedRecords = updateWalletsItems(wallets, item);
                dispatch({ type: CUSTODY_WALLETS_UPDATE, wallets: updatedRecords });
            },
            addNote: (item) => {
                const updatedRecords = updateWalletsItems(wallets, item);
                dispatch({ type: CUSTODY_WALLETS_UPDATE, wallets: updatedRecords });
            },
            markAsMigrated: (item) => {
                if (!withMigrated) {
                    const updatedRecords = wallets.filter((w) => w.code !== item.code);
                    dispatch({ type: CUSTODY_WALLETS_UPDATE, wallets: updatedRecords });
                }
            },
            activateWallet: (item) => {
                const updatedWallets = updateWalletsItems(wallets, item);
                dispatch({ type: CUSTODY_WALLETS_UPDATE, wallets: updatedWallets });
            },
            updateBalance: () => undefined,
        },
        actionsComponents: {
            addNote: AddNote,
            updateLabel: WalletLabel,
            updateBalance: UpdateBalance,
            markAsMigrated: WalletMigrate,
            activateWallet: WalletEnableDisable,
            adminUnAssign: ConnectedWalletCustomer,
            selectCustomer: ConnectedWalletCustomer,
        },
        actionsList: [
            { name: 'Add note', action: 'addNote' },
            { name: 'Update label', action: 'updateLabel' },
            { name: 'Refresh Balance', action: 'updateBalance' },
            { name: 'Select customer', action: 'selectCustomer' },
            { name: 'Mark as migrated', action: 'markAsMigrated' },
            { name: 'Unassign customer', action: 'adminUnAssign' },
            { name: 'Activate/Deactivate', action: 'activateWallet' },
        ],
    };
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const toggleZeroBalances = (evt) => {
        const { checked } = evt.target;
        setDisplayZero(checked);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find((c) => c.key === colKey);
        const colIndex = columnsToRender.findIndex((c) => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            // hide column: remove
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            // show column: insert
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            // default: do nothing
            setColumnsToRender([...columnsToRender]);
        }
        // give all animations a bit of a space to finish
        dispatch(applyAllSettingsChanges('custody'));
    };
    const fetchAllWallets = (code, migrated, cancelToken) => {
        var _a, _b;
        const sortByOption = sortBy && FILTER_OPTIONS[sortBy];
        const walletService = new CustodyWalletService({
            // eslint-disable-next-line max-len
            url: `/custody/${code}/wallets`,
            params: {
                limit: 10,
                page: currentWalletPage,
                sort_by: sortByOption,
                sort_direction: sortDirection,
                migrated,
                state: ((_a = filtersState === null || filtersState === void 0 ? void 0 : filtersState.find((f) => f.name === 'state')) === null || _a === void 0 ? void 0 : _a.value) || 'all',
                currency_code: (_b = filtersState === null || filtersState === void 0 ? void 0 : filtersState.find((f) => f.name === 'currency_code')) === null || _b === void 0 ? void 0 : _b.value,
            },
            method: 'get',
            cancelToken,
        });
        setLoading(true);
        dispatch({ type: LOADING_WALLETS_UPDATE, loadingWallets: true });
        walletService
            .makeRequest()
            .then((data) => {
            dispatch({ type: CUSTODY_WALLETS_UPDATE, wallets: data.records });
            dispatch({ type: LOADING_WALLETS_UPDATE, loadingWallets: false });
            setTotalPages(data.total);
            setLoading(false);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                // to avoid any state update
                // on unmounted component
                return;
            }
            const message = composeErrorMessage(e, Messages.CUSTODY_WALLETS_FETCH);
            dispatch({ type: CUSTODY_WALLETS_UPDATE, wallets: [] });
            dispatch({ type: LOADING_WALLETS_UPDATE, loadingWallets: false });
            setLoading(false);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const onPageChange = (event, pageNumber) => {
        setCurrentWalletPage(pageNumber + 1);
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const onSortHandle = (key) => {
        setCurrentWalletPage(1);
        setSortBy(key);
        setSortDirection((prev) => (prev === 'desc' ? 'asc' : 'desc'));
    };
    const handleAddWallet = (payload) => {
        const service = new CustodyWalletService({
            url: '/custody/wallets',
            method: 'post',
            data: payload,
        });
        setAddWalletLoading(true);
        service
            .makeRequest()
            .then(() => {
            fetchAllWallets(clientCode, withMigrated);
            showNotification({
                message: 'Wallet successfully created',
                color: 'success',
                dispatch: errorNotice,
            });
            setAddWalletLoading(false);
            toggleModal();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CUSTODY_WALLETS_CREATE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            setAddWalletLoading(false);
            toggleModal();
        });
    };
    const updateFilter = useCallback((filters) => {
        setFiltersState([...filters]);
        setCurrentWalletPage(1);
    }, [filtersState]);
    useEffect(() => {
        let code = '';
        if (selectedClient) {
            code = selectedClient.value;
        }
        else if (clientInfo) {
            code = clientInfo.code;
        }
        setClientCode(code);
    }, [clientInfo, selectedClient]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (clientCode) {
            fetchAllWallets(clientCode, withMigrated, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [clientCode, withMigrated, sortBy, sortDirection, currentWalletPage, filtersState]);
    useEffect(() => {
        let w = hideZero ? allWallets.filter((w) => w.balance_total !== '0') : allWallets;
        if (ctx.service && ctx.rates && allWallets.length > 0) {
            const conversionService = ctx.service;
            w = w.map((i) => Object.assign({}, i, {
                usd_total: conversionService.convertInBase(i.currency_ticker.toLowerCase(), i.balance_total),
                usd_available: conversionService.convertInBase(i.currency_ticker.toLowerCase(), i.balance_available),
                usd_locked: conversionService.convertInBase(i.currency_ticker.toLowerCase(), i.balance_locked),
            }));
        }
        setWalletsToRender(w);
    }, [walletsFiltered, allWallets, ctx.rates, hideZero]);
    useEffect(() => {
        const filteredZeroWallets = hideZero ? allWallets.filter((w) => w.balance_total !== '0') : allWallets;
        setWalletsFiltered([...filteredZeroWallets]);
    }, [hideZero]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter((c) => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(walletsWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [walletsWidgetHeight]);
    return (React.createElement("div", null,
        React.createElement(PanelContainer, { role: 'tree' },
            modalOpen ? (React.createElement(WalletModal, { open: modalOpen, toggleFunc: toggleModal, loading: addWalletLoading, handleAddWallet: handleAddWallet })) : null,
            React.createElement(WalletsFilters, { hideZero: hideZero, features: features, toggleModal: toggleModal, withMigrated: withMigrated, onFiltersChange: updateFilter, setWithMigrated: setWithMigrated, toggleZeroBalances: toggleZeroBalances }),
            React.createElement(WalletsClientContainer, null,
                React.createElement(Box, null,
                    React.createElement(IconButton, { onClick: handleColumnsClick, size: 'large' },
                        React.createElement(ViewColumnIcon, null)),
                    React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                        React.createElement(MaterialPopoverWrap, null,
                            React.createElement(List, { dense: true, component: 'nav', "aria-label": 'more actions' }, columns.map((col) => (React.createElement(ListItem, { key: col.id },
                                React.createElement(ListItemText, { primary: col.title }),
                                React.createElement(ListItemSecondaryAction, null,
                                    React.createElement(GreenSwitch, { edge: 'end', size: 'small', color: 'default', onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find((c) => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
            loading ? (React.createElement(Container, null,
                React.createElement(SpinnerContainer, null,
                    React.createElement(CircularProgress, null)),
                ' ')) : walletsToRender.length === 0 ? (React.createElement(Container, null,
                React.createElement(Alert, { severity: 'info', variant: 'outlined' }, "No items found"))) : (React.createElement(React.Fragment, null,
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: 'small', stickyHeader: true, "aria-label": 'sticky table' },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                columnsToRender.map((column) => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: 'left' }, column.key && FILTER_OPTIONS[column.key] ? (React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title)) : (column.title)))),
                                React.createElement(TableCell, { key: 'actions', align: 'right' }, "Actions"))),
                        React.createElement(TableBody, null, walletsToRender.map((wallet) => (React.createElement(TableRow, { hover: true, tabIndex: -1, role: 'checkbox', key: wallet.code },
                            columnsToRender.map((column, i) => {
                                const key = column.key;
                                const value = key ? wallet[key] : undefined;
                                return React.createElement(WalletTableCell, { key: i, value: value, columnKey: key, wallet: wallet });
                            }),
                            React.createElement(WalletsCellActions, { item: wallet, theme: theme, features: features, admin: masterTenant, actions: cellActions }))))))),
                React.createElement(TablePagination, { component: 'div', count: totalPages, page: currentWalletPage - 1, rowsPerPage: rowsPerPage, onPageChange: onPageChange, rowsPerPageOptions: [rowsPerPage] }))))));
};
const mapStateToProps = (state, ownProps) => ({
    theme: state.theme.className,
    wallets: state.custody.wallets,
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    allWallets: getWalletsToRender(state),
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    walletsWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(WidgetWallets);

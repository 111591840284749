import { CssBaseline, StyledEngineProvider, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import React from 'react';
import '@mui/styles';
const ThemeComponent = ({ children, themeName }) => {
    const theme = createTheme({
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        scrollbarColor: themeName === 'theme-dark' ? '#1c243f' : '#ffffff',
                        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                            width: 8,
                            height: 8,
                            backgroundColor: themeName === 'theme-dark' ? '#1c243f' : '#ffffff',
                        },
                        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                            borderRadius: 4,
                            backgroundColor: themeName === 'theme-dark' ? '#d6d6d636' : '#6b6b6b73',
                            minHeight: 24,
                            border: 'none',
                        },
                        '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                            backgroundColor: themeName === 'theme-dark' ? '#7267d066' : '#959595',
                        },
                        '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                            backgroundColor: themeName === 'theme-dark' ? '#7267d066' : '#959595',
                        },
                        '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: themeName === 'theme-dark' ? '#7267d066' : '#959595',
                        },
                        '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                            backgroundColor: themeName === 'theme-dark' ? '#d6d6d636' : '#6b6b6b73',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundImage: themeName === 'theme-dark' ? 'linear-gradient(#0a0a3100, #0a0a31)' : 'none',
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        backgroundImage: 'none',
                    },
                },
            },
        },
        palette: Object.assign({ mode: themeName === 'theme-dark' ? 'dark' : 'light' }, (themeName === 'theme-dark'
            ? {
                primary: {
                    main: '#60dbb8',
                },
                secondary: {
                    main: '#4f41c5',
                },
                success: {
                    main: '#3DF571',
                },
                info: {
                    main: '#a8cbe2',
                },
                error: {
                    main: '#F53D3D',
                },
                background: {
                    paper: '#1c243f',
                    default: '#090a31ff',
                },
            }
            : {
                primary: {
                    main: '#006b55',
                },
                secondary: {
                    main: '#4b635a',
                },
                info: {
                    main: '#406376',
                },
                error: {
                    main: '#ba1a1a',
                },
                background: {
                    paper: '#ffffff',
                    default: '#f0f0f0',
                },
            })),
        typography: {
            fontFamily: 'Inter, sans-serif',
        },
    });
    return (React.createElement(StyledEngineProvider, { injectFirst: true },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(CssBaseline, null),
            children)));
};
export const ConnectedThemeComponent = connect((state) => ({
    themeName: state.theme.className,
}))(ThemeComponent);

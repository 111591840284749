import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import Settings from '../../containers/Dashboards/Crypto/components/Settings';
import { getWidgetSettings } from '../../redux/selectors/widgetSettingsSelector';
import PlaceOrder from '../../containers/Dashboards/Crypto/components/PlaceOrder';
import WidgetManageOrders from '../../containers/Dashboards/Admin/WidgetManageOrders';
import SettingsModal from '../../containers/Dashboards/Crypto/components/SettingsModal';
import MarketOverview from '../../containers/Dashboards/Crypto/components/MarketOverview';
import MergedOrderList from '../../containers/Dashboards/Crypto/components/MergedOrderList';
import Strategies from '../../containers/Dashboards/MasterTenant/components/DeFi/WidgetStrategies';
import WidgetLogbook from '../../containers/Dashboards/Admin/components/LogBook/WidgetLogbook';
import ManageApprove from '../../containers/Dashboards/Admin/components/Whitelisting/WidgetApprove';
import WidgetTokens from '../../containers/Dashboards/Admin/components/ApiTokens/WidgetTokens';
import Permissions from '../../containers/Dashboards/Admin/components/Clients/Permissions/WidgetPermissions';
import TradingTransfers from '../../containers/Dashboards/Admin/components/TradingTransfers/WidgetTradingTransfers';
import WidgetClients from '../../containers/Dashboards/Custody/components/Admin/WidgetClients';
import ExchangesBalances from '../../containers/Dashboards/Crypto/components/ExchangesBalances';
import WidgetWallets from '../../containers/Dashboards/Custody/components/Wallets/WidgetWallets';
import WidgetApprove from '../../containers/Dashboards/Custody/components/Approve/WidgetApprove';
import Users from '../../containers/Dashboards/Admin/components/Clients/Users/WidgetManageUsers';
import AggregatedOrderbook from '../../containers/Dashboards/Crypto/components/AgregatedOrderBook';
import RFQRisks from '../../containers/Dashboards/MasterTenant/components/RFQ/Risks/WidgetRisks';
import TradeHistory from '../../containers/Dashboards/Crypto/components/ExchangeTradeHistory/index';
import WidgetsDeposits from '../../containers/Dashboards/Custody/components/Wallets/WidgetsDeposits';
import WidgetOrders from '../../containers/Dashboards/MasterTenant/components/PlaceOrder/WidgetOrders';
import PlaceOrderContainer from '../../containers/Dashboards/MasterTenant/components/PlaceOrder/main';
import WidgetExchange from '../../containers/Dashboards/MasterTenant/components/Exchanges/WidgetExchange';
import WidgetSchedulers from '../../containers/Dashboards/MasterTenant/components/Schedulers/WidgetSchedulers';
import Approve from '../../containers/Dashboards/MasterTenant/components/Compliance/Approve/WidgetApprove';
import Compliance from '../../containers/Dashboards/MasterTenant/components/Compliance/WidgetManageCompliance';
import WidgetTransactions from '../../containers/Dashboards/MasterTenant/components/Compliance/Transactions/WidgetTransactions';
import WidgetComplianceWallets from '../../containers/Dashboards/MasterTenant/components/Compliance/Wallets/WidgetWallets';
import TradingCompliance from '../../containers/Dashboards/MasterTenant/components/Compliance/WidgetTradingCompliance';
import WidgetTradingCharts from '../../containers/Dashboards/MasterTenant/components/Compliance/Charts/ComplianceTradingCharts';
import WidgetHoldings from '../../containers/Dashboards/Portfolio/components/Holdings/WidgetHoldings';
import WidgetAddresses from '../../containers/Dashboards/Custody/components/Whitelist/WidgetAddresses';
import ClientProtocol from '../../containers/Dashboards/MasterTenant/components/RFQ/ClientProtocol/WidgetClientProtocol';
import QuoteMonitor from '../../containers/Dashboards/MasterTenant/components/RFQ/Streams/WidgetQuoteMonitor';
import Autohedger from '../../containers/Dashboards/MasterTenant/components/Autohedger/WidgetAutohedger';
import CurrencyConfig from '../../containers/Dashboards/MasterTenant/components/CurrencyConfig/WidgetCurrencyConfig';
import AutohedgerBalance from '../../containers/Dashboards/MasterTenant/components/AutohedgerBalance/WidgetAutohedgerBalance';
import OrderListSettings from '../../containers/Dashboards/Crypto/components/Settings/OrderListSettings';
import WidgetWithdrawals from '../../containers/Dashboards/Custody/components/Wallets/WidgetWithdrawals';
import TradingviewRealTimeChart from '../../containers/Dashboards/Crypto/components/TradingviewRealTimeChart';
import WidgetUnusedInvites from '../../containers/Dashboards/Admin/components/Clients/Users/WidgetUnusedInvites';
import WidgetRfqOrders from '../../containers/Dashboards/Crypto/components/RfqOrders/WidgetRfqOrders';
import { Box, IconButton, Typography } from '@mui/material';
import WIdgetInternalTransfers from '../../containers/Dashboards/Custody/components/InternalTransfers/WIdgetInternalTransfers';
import AutohedgerPnL from '../../containers/Dashboards/MasterTenant/components/AutohedgerPnL/AutohedgerPnL';
import WidgetFIATDeposits from '../../containers/Dashboards/Admin/components/TradingTransfers/FIATDeposits/WidgetFIATDeposits';
const widgetsComponents = {
    manageUsers: Users,
    rfqRisks: RFQRisks,
    strategies: Strategies,
    manageApprove: Approve,
    placeOrder: PlaceOrder,
    permissions: Permissions,
    approve: WidgetApprove,
    clients: WidgetClients,
    wallets: WidgetWallets,
    autohedger: Autohedger,
    placeOrders: WidgetOrders,
    portfolio: WidgetHoldings,
    deposits: WidgetsDeposits,
    manageTokens: WidgetTokens,
    tradeHistory: TradeHistory,
    addresses: WidgetAddresses,
    rfqOrders: WidgetRfqOrders,
    quoteMonitor: QuoteMonitor,
    ordersList: MergedOrderList,
    manageLogbook: WidgetLogbook,
    manageCompliance: Compliance,
    adminExchange: WidgetExchange,
    manageWhitelist: ManageApprove,
    currencyConfig: CurrencyConfig,
    clientProtocol: ClientProtocol,
    marketOverview: MarketOverview,
    withdrawals: WidgetWithdrawals,
    hedgeOrders: PlaceOrderContainer,
    manageOrders: WidgetManageOrders,
    manageSchedulers: WidgetSchedulers,
    manageInvites: WidgetUnusedInvites,
    tradingTransfers: TradingTransfers,
    autohedgerBalance: AutohedgerBalance,
    exchangesBalances: ExchangesBalances,
    tradingView: TradingviewRealTimeChart,
    aggregatedOrderbook: AggregatedOrderbook,
    complianceTransactions: WidgetTransactions,
    complianceWallets: WidgetComplianceWallets,
    manageComplianceTrading: TradingCompliance,
    complianceTradingCharts: WidgetTradingCharts,
    internalTransfers: WIdgetInternalTransfers,
    autohedgerPnL: AutohedgerPnL,
    FIATDeposits: WidgetFIATDeposits,
};
const WidgetBody = ({ widgetKey, widgetType }) => {
    if (!widgetKey) {
        return null;
    }
    const [modalOpen, setModalOpen] = useState(false);
    const [subPage, setSubPage] = useState(0);
    const [splitPage, setSplitPage] = useState(0);
    const [regularPage, setRegularPage] = useState(0);
    const settings = useSelector(getWidgetSettings);
    const titleSetting = useMemo(() => settings.find((s) => s && s.id === `${widgetKey}Title`), [settings]);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    return (React.createElement(Box, { sx: { height: '100%', display: 'flex', flexDirection: 'column' } },
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'space-between' }, p: 1 },
            React.createElement(Box, { sx: { flex: 1 }, className: 'draggable-header', component: 'div' },
                React.createElement(Typography, { variant: 'subtitle1', style: { textTransform: 'uppercase' } },
                    React.createElement(React.Fragment, null, titleSetting ? titleSetting.value : ''))),
            React.createElement(IconButton, { size: 'small', color: 'default', "aria-label": 'settings', component: 'span', onClick: toggleModal },
                React.createElement(SettingsIcon, { fontSize: 'small' }))),
        typeof widgetsComponents[widgetType] === 'undefined' ? (React.createElement(Box, { p: 2 },
            React.createElement(Typography, { variant: 'h6' }, "Widget is not existing anymore. Please remove it from the dashboard and save your workspace"))) : (React.createElement(Box, { sx: { height: '100%' } }, React.createElement(widgetsComponents[widgetType], {
            widgetKey,
            widgetType,
            regularPage,
            splitPage,
            subPage,
            setSubPage,
            setSplitPage,
            setRegularPage,
        }))),
        modalOpen ? (React.createElement(SettingsModal, { open: modalOpen, toggleFunc: toggleModal }, widgetType === 'ordersList' ? (React.createElement(OrderListSettings, { toggleFunc: toggleModal, widgetKey: widgetKey, setSubPage: setSubPage, setSplitPage: setSplitPage, setRegularPage: setRegularPage })) : (React.createElement(Settings, { toggleFunc: toggleModal, widgetKey: widgetKey })))) : null));
};
export default WidgetBody;

import React from 'react';
import { Box, Grid, Button, TextField, Typography, CircularProgress, Link, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LockIcon from '@mui/icons-material/Lock';
import Alert from '@mui/material/Alert';
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
const TotpForm = ({ fields, loading, errorMsg, errorMessage, validation, handleSubmit, onFieldChange, }) => {
    const checkValidation = (fieldName) => {
        const fieldError = validation.find(e => e.name === fieldName);
        return fieldError && fieldError.message;
    };
    const isEmptyFields = fields.totp_code !== '';
    const helperTextStyles = useHelperTextStyles();
    return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(Box, { display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 },
            React.createElement(Typography, { variant: 'subtitle1', align: 'center' }, "Provide TOTP code"),
            (!!errorMessage || !!errorMsg) ?
                React.createElement(Alert, { color: "warning", variant: "outlined" },
                    errorMessage,
                    errorMsg) : null,
            React.createElement(Grid, { container: true, alignItems: 'center' },
                React.createElement(Grid, { item: true, xs: 1 },
                    React.createElement(LockIcon, { fontSize: "small" })),
                React.createElement(Grid, { item: true, xs: 11 },
                    React.createElement(TextField, { autoFocus: true, fullWidth: true, id: "totp_code", size: "small", name: "totp_code", placeholder: "TOTP code", variant: "outlined", value: fields.totp_code, onChange: onFieldChange, className: "mui_select_wrap", InputLabelProps: { shrink: true }, helperText: `${checkValidation('totp_code') || ''}`, FormHelperTextProps: { classes: { root: helperTextStyles.root } } }))),
            React.createElement(Box, { mt: 3 },
                React.createElement(Button, { type: "submit", variant: "contained", fullWidth: true, disabled: (validation.length > 0 || loading) || !isEmptyFields || fields.totp_code.length !== 6 }, loading ? (React.createElement(CircularProgress, { size: "24.5px" })) : 'Send Code')),
            React.createElement("div", null,
                React.createElement(Link, { href: "mailto:contact@dltmarkets.com" }, "Not working? Please, contact us!")))));
    // }
};
export default TotpForm;

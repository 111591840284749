var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import isEqual from 'lodash/isEqual';
import RGL from 'react-grid-layout';
import { Box, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { generateLayout } from '../../shared/helpers/layout';
import WidgetBody from '../../shared/renderProps/WidgetContent';
import LayoutContainer from '../../shared/renderProps/LayoutContainer';
import { getWidgets } from '../../redux/selectors/widgetSettingsSelector';
import { SCHEMA_UPDATE_LAYOUT_PROPS } from '../../redux/actionTypes/widgetSettingsActionTypes';
import { LoaderWrapper } from '../../shared/components/styled/loaderWrapper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const ReactGridLayout = RGL;
// eslint-disable-next-line react/display-name
const CustomHandle = React.forwardRef((props, ref) => {
    const { handleAxis } = props, restProps = __rest(props, ["handleAxis"]);
    return (React.createElement("div", Object.assign({ ref: ref, style: {
            bottom: 0,
            right: 0,
            position: 'absolute',
            width: '20px',
            height: '20px',
        }, className: `handle-${handleAxis}` }, restProps),
        React.createElement(ChevronRightIcon, { fontSize: 'small', sx: { transform: 'rotate(45deg)', ':hover': { cursor: 'se-resize' } } })));
});
/* eslint-disable react/no-redundant-should-component-update, no-param-reassign */
class Dashboard extends React.Component {
    constructor() {
        var _a;
        super(...arguments);
        this.componentRef = React.createRef();
        this.state = {
            layouts: [],
            width: (_a = this.componentRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().width,
            widgets: [],
        };
        this.updateComponentWidth = () => {
            var _a;
            const componentWidth = (_a = this.componentRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().width;
            this.setState(Object.assign(Object.assign({}, this.state), { width: componentWidth }));
        };
        this.handleLayoutChange = (layout) => {
            const { dispatchUpdateLayoutProps } = this.props;
            const widgets = [...this.props.widgets];
            widgets.forEach((w) => {
                const lItem = layout.find((l) => l.i === w.widgetKey);
                if (lItem) {
                    w.layoutProps = Object.assign({}, w.layoutProps, {
                        h: lItem.h,
                        w: lItem.w,
                        x: lItem.x,
                        y: lItem.y,
                    });
                }
            });
            dispatchUpdateLayoutProps(widgets);
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.sideMenuCollapsed !== prevProps.sideMenuCollapsed) {
            this.updateComponentWidth();
        }
    }
    componentDidMount() {
        const { widgets } = this.props;
        if (widgets.length) {
            this.setState({
                layouts: generateLayout(widgets),
                widgets
            });
        }
        window.addEventListener('resize', this.updateComponentWidth);
    }
    shouldComponentUpdate(nextProps) {
        var _a, _b;
        const nextWidgets = nextProps.widgets;
        const { widgets } = this.props;
        const currentWidth = (_a = this.componentRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().width;
        if (!isEqual(widgets, nextWidgets) || currentWidth !== this.state.width) {
            this.setState({
                layouts: generateLayout(nextWidgets),
                width: ((_b = this.componentRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().width) || this.state.width,
                widgets: nextWidgets
            });
        }
        return true;
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateComponentWidth);
    }
    render() {
        const { loadingWorkspaces, isAdmin } = this.props;
        const { layouts, widgets } = this.state;
        if (loadingWorkspaces) {
            return (React.createElement(LoaderWrapper, { ref: this.componentRef },
                React.createElement(CircularProgress, { size: '33px' })));
        }
        if (layouts.length === 0) {
            return null;
        }
        return (React.createElement(Box, { ref: this.componentRef, width: '100%', height: '100%' }, this.state.width ? (React.createElement(ReactGridLayout, { useCSSTransforms: false, className: 'layout', cols: 12, width: this.state.width, layout: layouts, margin: [8, 8], draggableHandle: '.draggable-header', rowHeight: 30, resizeHandle: React.createElement(CustomHandle, null), resizeHandles: ['se'], onLayoutChange: (layout) => this.handleLayoutChange(layout) }, widgets.map((widget) => {
            if (!isAdmin && widget.adminOnly) {
                return;
            }
            return (React.createElement(Box, { key: widget.widgetKey, sx: { position: 'relative' } },
                React.createElement(LayoutContainer, { widgetKey: widget.widgetKey },
                    React.createElement(WidgetBody, { widgetKey: widget.widgetKey, widgetType: widget.type }))));
        }))) : null));
    }
}
const stateToProps = (state) => ({
    widgets: getWidgets(state),
    features: state.client.features,
    loadingWorkspaces: state.workspace.loading,
    sideMenuCollapsed: state.workspace.sideMenuCollapsed,
    isAdmin: state.client.masterTenant,
});
const dispatchToProps = (dispatch) => ({
    dispatchUpdateLayoutProps: (widgets) => dispatch({ type: SCHEMA_UPDATE_LAYOUT_PROPS, widgets }),
});
export default connect(stateToProps, dispatchToProps)(Dashboard);

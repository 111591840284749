import React from 'react';
import * as DetailsSection from '@shared/components/styled/detailsSection';
import { Box, Typography } from '@mui/material';
import CopyValue from '@shared/components/CopyValueComponent';
const RowItem = (props) => {
    const { label, type, value = 'Not set', copy = false } = props;
    return (React.createElement(DetailsSection.RowItem, null,
        React.createElement(DetailsSection.ItemLabel, null, label),
        React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 1 },
            type === 'number' ? (React.createElement(DetailsSection.NumberValue, null, value)) : (React.createElement(Typography, null, value || 'Not set')),
            copy && value !== 'Not set' ? React.createElement(CopyValue, { value: value }) : null)));
};
const ClientInfoComponent = (props) => {
    var _a, _b, _c;
    const info = props.details;
    const clients = props.clients;
    return (React.createElement(DetailsSection.Layout, null,
        clients && (React.createElement(DetailsSection.Row, null,
            React.createElement(DetailsSection.RowTitle, null, "Client"),
            React.createElement(DetailsSection.RowContent, null,
                React.createElement(RowItem, { label: 'Name', value: info && ((_a = clients.find((c) => c.code === info.client_code)) === null || _a === void 0 ? void 0 : _a.company_name), type: 'text', copy: true }),
                React.createElement(RowItem, { label: 'Code', type: 'number', value: info && info.client_code, copy: true })))),
        React.createElement(DetailsSection.Row, null,
            React.createElement(DetailsSection.RowTitle, null, "Contact"),
            React.createElement(DetailsSection.RowContent, null,
                React.createElement(RowItem, { label: 'Contact', value: info === null || info === void 0 ? void 0 : info.contact_person_name, type: 'text' }),
                React.createElement(RowItem, { label: 'Phone', value: info === null || info === void 0 ? void 0 : info.phone_number, type: 'number' }),
                React.createElement(RowItem, { label: 'Email', value: info === null || info === void 0 ? void 0 : info.email, type: 'text', copy: true }))),
        React.createElement(DetailsSection.Row, null,
            React.createElement(DetailsSection.RowTitle, null, "Address"),
            React.createElement(DetailsSection.RowContent, null,
                React.createElement(RowItem, { label: 'Zip', value: info === null || info === void 0 ? void 0 : info.zip_code, type: 'number' }),
                React.createElement(RowItem, { label: 'Address', value: info === null || info === void 0 ? void 0 : info.address, type: 'text' }),
                React.createElement(RowItem, { label: 'City', value: info === null || info === void 0 ? void 0 : info.city, type: 'text' }),
                React.createElement(RowItem, { label: 'Country', value: info === null || info === void 0 ? void 0 : info.country, type: 'text' }))),
        React.createElement(DetailsSection.Row, null,
            React.createElement(DetailsSection.RowTitle, null, "Banking Details"),
            React.createElement(DetailsSection.RowContent, null,
                React.createElement(RowItem, { label: 'VAT', value: info === null || info === void 0 ? void 0 : info.vat, type: 'number' }),
                React.createElement(RowItem, { label: 'BFID', value: info === null || info === void 0 ? void 0 : info.bfid, type: 'number' }),
                info && 'fee' in info ? React.createElement(RowItem, { label: `Broker Fee`, value: `${info === null || info === void 0 ? void 0 : info.fee}%`, type: 'number' }) : null,
                React.createElement(RowItem, { label: `IBAN ${(info === null || info === void 0 ? void 0 : info.ibans) && ((_b = info === null || info === void 0 ? void 0 : info.ibans[0]) === null || _b === void 0 ? void 0 : _b.currency)}`, value: (info === null || info === void 0 ? void 0 : info.ibans) && ((_c = info === null || info === void 0 ? void 0 : info.ibans[0]) === null || _c === void 0 ? void 0 : _c.iban), type: 'number', copy: true })))));
};
export default ClientInfoComponent;

import { Box, Button, Checkbox, Grid, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
const feeOptions = [
    { value: 'fee_per_byte', label: 'Fee per Byte' },
    { value: 'network_fee', label: 'Network Fee' },
    { value: 'gas_price', label: 'Gas Price' },
    { value: 'without_network_fee', label: 'No Fee' },
];
const formSchema = yup
    .object({
    name: yup.string().required().default(''),
    can_have_child_wallets: yup.boolean().required(),
    is_multi_source: yup.boolean().required(),
    is_boostable: yup.boolean().required(),
    is_utxo: yup.boolean().required(),
    elliptic_network_code: yup.string().required(),
    trmlabs_network_code: yup.string().required(),
    explorer_url: yup.string().required(),
    fees_type: yup
        .string()
        .required()
        .oneOf(['fee_per_byte', 'network_fee', 'gas_price', 'without_network_fee']),
})
    .required();
const formConfig = {
    name: { label: 'Name', type: 'text', required: true },
    elliptic_network_code: { label: 'Elliptic Network Code', type: 'text', required: true },
    trmlabs_network_code: { label: 'TRMLabs Network Code', type: 'text', required: true },
    explorer_url: { label: 'Explorer URL', type: 'text', required: true },
    fees_type: {
        label: 'Fee Type',
        type: 'select',
        required: true,
        options: feeOptions,
    },
    can_have_child_wallets: { label: 'Child Wallets', type: 'checkbox', required: false },
    is_multi_source: { label: 'Multi Source', type: 'checkbox', required: false },
    is_boostable: { label: 'Boostable', type: 'checkbox', required: false },
    is_utxo: { label: 'UTXO', type: 'checkbox', required: false },
};
const NetworkUpdate = (props) => {
    const { onClose, onSave, network, loading } = props;
    const { handleSubmit, control, register, trigger, setValue, getValues, formState: { errors }, } = useForm({
        defaultValues: formSchema.cast({
            name: (network === null || network === void 0 ? void 0 : network.name) ? network.name : '',
            can_have_child_wallets: !!(network === null || network === void 0 ? void 0 : network.can_have_child_wallets),
            is_multi_source: !!(network === null || network === void 0 ? void 0 : network.is_multi_source),
            is_boostable: !!(network === null || network === void 0 ? void 0 : network.is_boostable),
            is_utxo: !!(network === null || network === void 0 ? void 0 : network.is_utxo),
            elliptic_network_code: (network === null || network === void 0 ? void 0 : network.elliptic_network_code) ? network.elliptic_network_code : '',
            trmlabs_network_code: (network === null || network === void 0 ? void 0 : network.trmlabs_network_code) ? network.trmlabs_network_code : '',
            explorer_url: (network === null || network === void 0 ? void 0 : network.explorer_url) ? network.explorer_url : '',
            fees_type: (network === null || network === void 0 ? void 0 : network.fees_type) || '',
        }),
        resolver: yupResolver(formSchema),
    });
    return (React.createElement(Box, { component: 'form', onSubmit: handleSubmit(onSave) },
        React.createElement(Grid, { container: true, spacing: 2, py: 2 }, Object.keys(formConfig).map((s, i) => {
            const key = s;
            const field = formConfig[key];
            return (React.createElement(Grid, { item: true, xs: 6, key: i },
                field.type === 'empty' && React.createElement(Box, null),
                field.type === 'text' && (React.createElement(TextField, Object.assign({ fullWidth: true, InputLabelProps: { shrink: true }, error: !!errors[key], size: 'small', required: field.required, label: field.label, placeholder: field.label }, register(key), { onChange: (e) => {
                        setValue(key, e.target.value);
                        trigger(key);
                    } }))),
                field.type === 'select' && (React.createElement(Controller, { name: key, control: control, render: ({ field }) => {
                        var _a;
                        return (React.createElement(TextField, Object.assign({ fullWidth: true, select: true }, field, { size: 'small', error: !!errors[key], InputLabelProps: { shrink: true }, required: formConfig[key].required, label: formConfig[key].label, onChange: (e) => {
                                field.onChange(e.target.value);
                                setValue(key, e.target.value);
                                trigger(key);
                            } }), (_a = formConfig[key].options) === null || _a === void 0 ? void 0 : _a.map((o, i) => (React.createElement(MenuItem, { key: i, value: o.value }, o.label)))));
                    } })),
                field.type === 'checkbox' && (React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
                    React.createElement(Controller, { name: key, control: control, render: ({ field }) => (React.createElement(Checkbox, Object.assign({}, field, { required: formConfig[key].required, checked: !!getValues(key), onChange: (e, checked) => {
                                field.onChange(checked);
                                setValue(key, checked);
                                trigger(key);
                            } }))) }),
                    React.createElement(Typography, { variant: 'body1' }, field.label)))));
        })),
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'flex-end', gap: 1 } },
            React.createElement(LoadingButton, { loading: loading, type: 'submit', variant: 'contained' }, "Update"),
            React.createElement(Button, { onClick: onClose }, "Cancel"))));
};
export default NetworkUpdate;

import axios from 'axios';
import { getEnvSettings } from '../../config/environmentSettings';
export const fetchSettings = () => axios
    .get(`/runtime_settings`, { baseURL: getEnvSettings().adminApiUrl })
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const updateSettings = (settings) => axios
    .patch(`/runtime_settings`, Object.assign({}, settings), { baseURL: getEnvSettings().adminApiUrl })
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});

import React from 'react';
import { TransferStatus } from './transfersModel';
import TransactionUserInputReferenceDetails from './TransactionDetailsStates/UserInputReference';
import TransactionUserInputDepositDetails from './TransactionDetailsStates/UserInputDeposit';
import TransactionCompletedDetails from './TransactionDetailsStates/TransactionCompleted';
import TransactionFailedDetails from './TransactionDetailsStates/TransactionFailed';
import TransactionRevertedDetails from './TransactionDetailsStates/TransactionReverted';
import axios from 'axios';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import showNotification from '../../../../../../shared/helpers/notifications';
const TransactionStateComponents = {
    [TransferStatus.UserInputReference]: TransactionUserInputReferenceDetails,
    [TransferStatus.UserInputDeposit]: TransactionUserInputDepositDetails,
    [TransferStatus.Completed]: TransactionCompletedDetails,
    [TransferStatus.Reverted]: TransactionRevertedDetails,
    [TransferStatus.Failed]: TransactionFailedDetails,
};
const DepositDetails = ({ transaction, onClose }) => {
    const handleClose = (config) => {
        onClose({ reload: config.reload });
    };
    const handleUpdate = (config, formValue) => {
        const workflowId = transaction.workflow_id;
        axios
            .post(`bank-transfer/${workflowId}/customer-update`, { decline: config.decline, customerUUID: formValue === null || formValue === void 0 ? void 0 : formValue.reference }, { baseURL: getEnvSettings().adminApiUrl })
            .then(() => {
            if (config.decline) {
                showNotification({ message: 'Transfer has been successfully rejected', color: 'warning' });
            }
            else {
                showNotification({ message: 'Reference has been successfully updated', color: 'success' });
            }
            handleClose({ reload: true });
        })
            .catch((error) => {
            var _a, _b;
            const message = ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data).message || ((_b = error.response) === null || _b === void 0 ? void 0 : _b.data).desc;
            showNotification({ message, color: 'error' });
        });
    };
    const handleApprove = () => {
        const workflowId = transaction.workflow_id;
        axios
            .post(`bank-transfer/${workflowId}/transfer-confirmation`, { approveTransfer: true }, { baseURL: getEnvSettings().adminApiUrl })
            .then(() => {
            showNotification({ message: 'Transfer has been successfully approved', color: 'success' });
            handleClose({ reload: true });
        })
            .catch((error) => {
            var _a;
            const message = ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data).message;
            showNotification({ message, color: 'error' });
        });
    };
    const handleReject = () => {
        const workflowId = transaction.workflow_id;
        axios
            .post(`bank-transfer/${workflowId}/transfer-confirmation`, { approveTransfer: false }, { baseURL: getEnvSettings().adminApiUrl })
            .then(() => {
            showNotification({ message: 'Transfer has been successfully rejected', color: 'warning' });
            handleClose({ reload: true });
        })
            .catch((error) => {
            const message = "Can't reject transfer";
            showNotification({ message, color: 'error' });
        });
    };
    const Component = TransactionStateComponents[transaction.state];
    return Component ? (React.createElement(Component, { transaction: transaction, onClose: () => handleClose({ reload: false }), onApprove: handleApprove, onReject: handleReject, onRejectCustomer: () => handleUpdate({ decline: true }), onUpdate: (formValue) => handleUpdate({ decline: false }, { reference: formValue.reference }) })) : null;
};
export default DepositDetails;

import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import Alert from '@mui/material/Alert';
import { Box, List } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../Custody/styling/style';
import { CancelOrder } from './OrdersActions';
import OrdersCellActions from './OrdersCellActions';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import OrdersTableCell from '../../../Admin/components/Clients/Orders/OrdersTableCell';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
const getOrdersToRender = (arr) => {
    if (arr.length === 0) {
        return [];
    }
    return arr.map((i) => {
        const convert = {
            code: i.code,
            client: i.client_code,
            amount: i.amount,
            status: i.status,
            created: i.created,
            base: i.market_item.base,
            quote: i.market_item.quote,
            executedPrice: i.executed.price,
            executedValue: i.executed.value,
            action_type: `${i.type} ${i.action}`,
            exchange: i.market_item.exchange_code,
            pair: `${i.market_item.base} > ${i.market_item.quote}`,
        };
        return convert;
    });
};
const PlaceOrdersList = ({ page, total, orders, setPage, columns, features, isLoading, rowsPerPage, masterTenant, setRowsPerPage, ordersWidgetHeight, }) => {
    const [maxHeight, setMaxHeight] = useState(480);
    const [ordersToRender, setOrdersToRender] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const dispatch = useDispatch();
    const columnsOpen = Boolean(anchorColumns);
    const cellActions = {
        actions: {
            cancelItem: () => undefined,
        },
        actionsComponents: {
            cancelItem: CancelOrder,
        },
        actionsList: [
            { name: 'Cancel', action: 'cancelItem' },
        ],
    };
    const onChangePage = (event, pageNumber) => {
        setPage(pageNumber);
    };
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('manageOrders'));
    };
    useEffect(() => {
        if (orders.length) {
            setOrdersToRender(getOrdersToRender(orders));
        }
        else {
            setOrdersToRender([]);
        }
    }, [orders]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(ordersWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [ordersWidgetHeight]);
    if (isLoading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    if (orders.length === 0) {
        return (React.createElement(Alert, { severity: "info", variant: "outlined" },
            React.createElement("p", null,
                React.createElement("span", { className: "bold-text" }, "No items found"))));
    }
    return React.createElement(React.Fragment, null,
        React.createElement(WalletsClientContainer, null,
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                    React.createElement(ViewColumnIcon, null)),
                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                            React.createElement(ListItemText, { primary: col.title }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
        React.createElement(MaterialTableWrap, null,
            React.createElement(Paper, { className: "mui_table_root" },
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'action_type' ? 'left' : 'right' }, column.title))),
                                React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
                        React.createElement(TableBody, null, ordersToRender.map(order => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() },
                            columnsToRender.map((column, i) => {
                                const key = column.key;
                                const value = key ? order[key] : undefined;
                                return (React.createElement(OrdersTableCell, { key: i, value: value, columnKey: key, base: order.base, quote: order.quote }));
                            }),
                            React.createElement(OrdersCellActions, { item: order, features: features, actions: cellActions, masterTenant: masterTenant }))))))),
                React.createElement(TablePagination, { page: page, count: total, component: "div", rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [maxHeight] }))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    isLoading: state.admin.isLoading,
    masterTenant: state.client.masterTenant,
    ordersWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(PlaceOrdersList);
